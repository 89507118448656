import ADD_GIFT_MUTATION from '~/graphql/mutations/AddGift';
import GET_GIFTS_QUERY from '~/graphql/queries/GetGifts';
import REMOVE_GIFT_MUTATION from '~/graphql/mutations/RemoveGift';
import UPDATE_GIFT_MUTATION from '~/graphql/mutations/UpdateGift';

import { error } from '~/mixins/apollo';

export default {
  apollo: {
    gifts: {
      query: GET_GIFTS_QUERY,
      variables() {
        return {
          willId: this.willId,
        };
      },
      update: (data) => data.getGifts && data.getGifts.gifts,
      skip() {
        return !this.token || !this.willId;
      },
      error,
    },
  },
  data() {
    return {
      ADD_GIFT_MUTATION,
      REMOVE_GIFT_MUTATION,
      UPDATE_GIFT_MUTATION,
      gifts: [],
    };
  },
  computed: {
    getGiftsQuery() {
      return {
        query: GET_GIFTS_QUERY,
        variables: {
          willId: this.willId,
        },
      };
    },
  },
  methods: {
    getGiftFromEntity(entity) {
      return this.gifts.find(
        (gift) =>
          (gift.charity && gift.charity.id === entity.id) ||
          (gift.person && gift.person.id === entity.id)
      );
    },
    isGiftRecipient(entity) {
      return !!this.getGiftFromEntity(entity);
    },
    async removeAllGifts() {
      await Promise.all(
        this.gifts.map((gift) => {
          return this.removeGift(gift);
        })
      );
    },
    async removeGift(gift) {
      await this.$apollo.mutate({
        mutation: REMOVE_GIFT_MUTATION,
        variables: {
          id: gift.id,
          willId: this.willId,
        },
        update: (store) => {
          const data = store.readQuery(this.getGiftsQuery);
          const index = data.getGifts.gifts.findIndex((m) => m.id === gift.id);

          if (index !== -1) {
            data.getGifts.gifts.splice(index, 1);

            store.writeQuery({
              ...this.getGiftsQuery,
              data,
            });
          }
        },
      });
    },
  },
};
