import countryList from 'country-list';

export const state = () => ({
  australianStates: ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'],
  countries: countryList.getNames().sort(),
  showConfirmPasswordModal: false,
  showMobileNav: false,
  showSubmitHelperModal: false,
  showSubscriptionExpiredModal: false,
  showUnlockWillModal: false,
  showNotifyCharityConsentModal: false,
  showFlaggedWillAttentionModal: false,
});

export const getters = {
  australianStates: (state) => state.australianStates,
  countries: (state) => state.countries,
  showConfirmPasswordModal: (state) => state.showConfirmPasswordModal,
  showMobileNav: (state) => state.showMobileNav,
  showSubmitHelperModal: (state) => state.showSubmitHelperModal,
  showSubscriptionExpiredModal: (state) => state.showSubscriptionExpiredModal,
  showUnlockWillModal: (state) => state.showUnlockWillModal,
  showNotifyCharityConsentModal: (state) => state.showNotifyCharityConsentModal,
  showFlaggedWillAttentionModal: (state) => state.showFlaggedWillAttentionModal,
};

export const mutations = {
  showConfirmPasswordModal(state, value) {
    state.showConfirmPasswordModal = value;
  },

  showMobileNav(state, value) {
    state.showMobileNav = value;
  },
  showSubmitHelperModal(state, value) {
    state.showSubmitHelperModal = value;
  },
  showSubscriptionExpiredModal(state, value) {
    state.showSubscriptionExpiredModal = value;
  },
  showUnlockWillModal(state, value) {
    state.showUnlockWillModal = value;
  },
  showNotifyCharityConsentModal(state, value) {
    state.showNotifyCharityConsentModal = value;
  },
  showFlaggedWillAttentionModal(state, value) {
    state.showFlaggedWillAttentionModal = value;
  },
};

export const actions = {
  setShowConfirmPasswordModal({ commit }, value) {
    commit('showConfirmPasswordModal', value);
  },
  setShowMobileNav({ commit }, value) {
    commit('showMobileNav', value);
  },
  setShowSubmitHelperModal({ commit }, value) {
    commit('showSubmitHelperModal', value);
  },
  setShowSubscriptionExpiredModal({ commit }, value) {
    commit('showSubscriptionExpiredModal', value);
  },
  setShowUnlockWillModal({ commit }, value) {
    commit('showUnlockWillModal', value);
  },
  setShowNotifyCharityConsentModal({ commit }, value) {
    commit('showNotifyCharityConsentModal', value);
  },
  setShowFlaggedWillAttentionModal({ commit }, value) {
    commit('showFlaggedWillAttentionModal', value);
  },
  resetUI({ commit }) {
    commit('showConfirmPasswordModal', false);
    commit('showMobileNav', false);
    commit('showSubscriptionExpiredModal', false);
    commit('showUnlockWillModal', false);
    commit('showNotifyCharityConsentModal', false);
    commit('showFlaggedWillAttentionModal', false);
  },
};
