import { getExperimentVariantValue } from '~/utilities';

export default function({ store, redirect, route }) {
  /* eslint-disable camelcase */
  const { is_adult, is_resident } = store.state.signup.meta;
  switch (route.path) {
    case '/get-started':
    case '/get-started/': {
      const ORIGINAL_SIGNUP_VARIANT = '0';
      const SINGLE_PAGE_SIGNUP_VARIANT = '1';
      const variant = getExperimentVariantValue({
        experimentId: process.env.SECOND_SINGLE_PAGE_SIGNUP_AB_EXPERIMENT_ID,
        defaultValue: ORIGINAL_SIGNUP_VARIANT,
      });
      if (variant === SINGLE_PAGE_SIGNUP_VARIANT) {
        return redirect({ path: '/register', query: route.query });
      }
      break;
    }
    case '/get-started/resident':
    case '/get-started/sign-up':
    case '/get-started/name':
    case '/get-started/callback':
      break;
    case '/get-started/age':
      if (!is_resident) {
        return redirect({ path: '/get-started', query: route.query });
      }
      break;
    default:
      if (!is_adult && !is_resident) {
        return redirect({ path: '/get-started', query: route.query });
      }
      break;
  }
  /* eslint-enable camelcase */
}
