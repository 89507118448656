import { FeatureFlagDefaults } from '@/plugins/featureFlags';
import { tryGetFromLocalStorage } from '~/utilities';

export const state = () => {
  const featureFlags = {};

  for (const key in FeatureFlagDefaults) {
    featureFlags[key] = tryGetFromLocalStorage(key, FeatureFlagDefaults[key]);
  }

  return {
    email: null,
    role: null,
    token: null,
    password: null,
    twoFactorEnabled: false,
    trackingActive: false,
    userId: null,
    willId: null,
    willStatus: null,
    verified: null,
    masquerading: null,
    poaId: null,
    featureFlags, // Feature Flags are set in store so that it is reactive and global
  };
};

export const getters = {
  email: (state) => state.email,
  role: (state) => state.role,
  token: (state) => state.token,
  password: (state) => state.password,
  twoFactorEnabled: (state) => state.twoFactorEnabled,
  trackingActive: (state) => state.trackingActive,
  userId: (state) => state.userId,
  willId: (state) => state.willId,
  willStatus: (state) => state.willStatus,
  verified: (state) => state.verified,
  masquerading: (state) => state.masquerading,
  poaId: (state) => state.poaId,
};

export const mutations = {
  setEmail(state, value) {
    state.email = value;
  },
  setRole(state, value) {
    state.role = value;
  },
  setToken(state, value) {
    state.token = value;
  },
  setPassword(state, value) {
    state.password = value;
  },
  setTwoFactorEnabled(state, value) {
    state.twoFactorEnabled = value;
  },
  setTrackingActive(state, value) {
    state.trackingActive = value;
  },
  setUserId(state, value) {
    state.userId = value;
  },
  setVerified(state, value) {
    state.verified = value;
  },
  setWillId(state, value) {
    state.willId = value;
  },
  setWillStatus(state, value) {
    state.willStatus = value;
  },
  setMasquerading(state, value) {
    state.masquerading = value;
  },
  setFeatureFlags(state, value) {
    state.featureFlags = value;
  },
  setPoaId(state, value) {
    state.poaId = value;
  },
};

export const actions = {
  setEmail({ commit }, value) {
    commit('setEmail', value);
  },
  setRole({ commit }, value) {
    commit('setRole', value);
  },
  setToken({ commit }, value) {
    commit('setToken', value);
  },
  setPassword({ commit }, value) {
    commit('setPassword', value);
  },
  setTwoFactorEnabled({ commit }, value) {
    commit('setTwoFactorEnabled', value);
  },
  setTrackingActive({ commit }, value) {
    commit('setTrackingActive', value);
  },
  setUserId({ commit }, value) {
    commit('setUserId', value);
  },
  setVerified({ commit }, value) {
    commit('setVerified', value);
  },
  setWillId({ commit }, value) {
    commit('setWillId', value);
  },
  setWillStatus({ commit }, value) {
    commit('setWillStatus', value);
  },
  setMasquerading({ commit }, value) {
    commit('setMasquerading', value);
  },
  setPoaId({ commit }, value) {
    commit('setPoaId', value);
  },
  resetUserState({ commit }) {
    commit('setEmail', null);
    commit('setRole', null);
    commit('setToken', null);
    commit('setPassword', null);
    commit('setTwoFactorEnabled', false);
    commit('setTrackingActive', false);
    commit('setUserId', null);
    commit('setVerified', null);
    commit('setWillId', null);
    commit('setWillStatus', null);
    commit('setMasquerading', null);
    commit('setPoaId', null);
  },
};
