import ADD_BENEFICIARY_MUTATION from '~/graphql/mutations/AddBeneficiary';
import GET_BENEFICIARIES_QUERY from '~/graphql/queries/GetBeneficiaries';
import REMOVE_BENEFICIARY_MUTATION from '~/graphql/mutations/RemoveBeneficiary';
import UPDATE_BENEFICIARY_MUTATION from '~/graphql/mutations/UpdateBeneficiary';

import { metaArrayToObject, objectToMetaArray } from '~/utilities';
import { error } from '~/mixins/apollo';

export default {
  apollo: {
    beneficiaries: {
      prefetch: false,
      fetchPolicy: 'no-cache',
      query: GET_BENEFICIARIES_QUERY,
      variables() {
        return {
          willId: this.willId,
        };
      },
      update: (data) => {
        return data.getBeneficiaries && data.getBeneficiaries.beneficiaries;
      },
      skip() {
        return !this.token || !this.willId;
      },
      error,
    },
  },
  data() {
    return {
      beneficiaries: [],
    };
  },
  watch: {
    beneficiaries(beneficiaries) {
      beneficiaries.map((b) => {
        b.meta = metaArrayToObject(b.meta);
        if (b.person) b.person.meta = metaArrayToObject(b.person.meta);
        if (b.charity) b.charity.meta = metaArrayToObject(b.charity.meta);
        b.backup.map((bu) => {
          bu.meta = metaArrayToObject(b.meta);
          if (bu.person) bu.person.meta = metaArrayToObject(bu.person.meta);
          if (bu.charity) bu.charity.meta = metaArrayToObject(bu.charity.meta);
        });
      });
    },
  },
  computed: {
    getBeneficiariesQuery() {
      return {
        query: GET_BENEFICIARIES_QUERY,
        variables: {
          willId: this.willId,
        },
      };
    },
    backupQueue() {
      return this.beneficiaries.filter((b) => b.person);
    },
    hasBeneficiaries() {
      return this.beneficiaries && this.beneficiaries.length > 0;
    },
  },
  methods: {
    async addBeneficiary(
      data,
      distribution,
      meta = [],
      type = 'PRIMARY',
      backupId
    ) {
      distribution = this.formatDistribution(distribution);
      meta = objectToMetaArray(meta);

      const variables = {
        willId: this.willId,
        charity: null,
        person: null,
        distribution,
        meta,
        type,
        backupId,
      };

      if (data.category) {
        variables.person = data.id;
      } else {
        variables.charity = data.id;
      }

      await this.$apollo
        .mutate({
          mutation: ADD_BENEFICIARY_MUTATION,
          variables,
        })
        .catch(error);
    },
    addBackupBeneficiary(parentBeneficiary, data, distribution, meta = []) {
      return this.addBeneficiary(
        data,
        distribution,
        meta,
        'BACKUP',
        parentBeneficiary.id
      );
    },
    formatDistribution(distribution) {
      return parseFloat(parseFloat(distribution).toFixed(2)).toString();
    },
    getBeneficiaryByCharity(charity) {
      return this.beneficiaries.find(
        (beneficiary) =>
          beneficiary.charity && beneficiary.charity.id === charity.id
      );
    },
    getBackupBeneficiaryByCharity(parentBeneficiary, charity) {
      parentBeneficiary.backup = parentBeneficiary.backup
        ? parentBeneficiary.backup
        : [];
      return parentBeneficiary.backup.find(
        (beneficiary) =>
          beneficiary.charity && beneficiary.charity.id === charity.id
      );
    },
    getBeneficiaryByPerson(person) {
      return this.beneficiaries.find(
        (beneficiary) =>
          beneficiary.person && beneficiary.person.id === person.id
      );
    },
    getBackupBeneficiaryByPerson(parentBeneficiary, person) {
      parentBeneficiary.backup = parentBeneficiary.backup
        ? parentBeneficiary.backup
        : [];
      return parentBeneficiary.backup.find(
        (beneficiary) =>
          beneficiary.person && beneficiary.person.id === person.id
      );
    },
    isBeneficiary(entity) {
      return !!(
        this.getBeneficiaryByCharity(entity) ||
        this.getBeneficiaryByPerson(entity)
      );
    },
    async removeAllBeneficiaries() {
      await Promise.all(
        this.beneficiaries.map(async (beneficiary) => {
          await this.removeAllBackupBeneficiaries(beneficiary);
          return this.removeBeneficiary(beneficiary);
        })
      );
    },
    async removeAllBackupBeneficiaries(parentBeneficiary) {
      parentBeneficiary.backup = parentBeneficiary.backup
        ? parentBeneficiary.backup
        : [];
      await Promise.all(
        parentBeneficiary.backup.map((beneficiary) => {
          return this.removeBeneficiary(beneficiary);
        })
      );
    },
    async removeBeneficiary(beneficiary) {
      await this.$apollo.mutate({
        mutation: REMOVE_BENEFICIARY_MUTATION,
        variables: {
          id: beneficiary.id,
          willId: this.willId,
        },
      });
    },
    async removeBackupBeneficiary(beneficiary) {
      await this.removeBeneficiary(beneficiary);
    },
    async removeBeneficiaryByCharity(charity) {
      const beneficiary = this.getBeneficiaryByCharity(charity);

      if (beneficiary) {
        await this.removeBeneficiary(beneficiary);
      }
    },
    async removeBackupBeneficiaryByCharity(parentBeneficiary, charity) {
      parentBeneficiary.backup = parentBeneficiary.backup
        ? parentBeneficiary.backup
        : [];
      const beneficiary = this.getBackupBeneficiaryByCharity(
        parentBeneficiary,
        charity
      );

      if (beneficiary) {
        await this.removeBeneficiary(beneficiary);
      }
    },
    async removeBeneficiaryByPerson(person) {
      const beneficiary = this.getBeneficiaryByPerson(person);

      if (beneficiary) {
        await this.removeBeneficiary(beneficiary);
      }
    },
    async removeBackupBeneficiaryByPerson(parentBeneficiary, person) {
      parentBeneficiary.backup = parentBeneficiary.backup
        ? parentBeneficiary.backup
        : [];
      const beneficiary = this.getBackupBeneficiaryByPerson(
        parentBeneficiary,
        person
      );

      if (beneficiary) {
        await this.removeBeneficiary(beneficiary);
      }
    },
    async updateBeneficiary(beneficiary, distribution) {
      const variables = {
        id: beneficiary.id,
        distribution: this.formatDistribution(distribution),
        meta: objectToMetaArray(beneficiary.meta),
        willId: this.willId,
      };

      await this.$apollo.mutate({
        mutation: UPDATE_BENEFICIARY_MUTATION,
        variables,
      });
    },
    async updateBackupBeneficiary(beneficiary, distribution) {
      await this.updateBeneficiary(beneficiary, distribution);
    },
    async refetchBeneficiaries() {
      await this.$apollo.queries.beneficiaries.refetch();
    },
  },
};
