import dateFormat from 'dateformat';
import isDate from 'lodash/isDate';

export const formatDate = (date, format = 'dd mmm "at" hh:MM tt') => {
  const parsed = parseDate(date);
  return dateFormat(parsed, format);
};

export const parseDate = (date) => {
  let parsed = date;

  if (!isDate(parsed)) {
    parsed = new Date(parsed);

    if (Number.isNaN(parsed.getTime())) {
      parsed = new Date(parseInt(date, 10));
    }
  }

  return parsed;
};

export default (ctx, inject) => {
  inject('formatDate', formatDate);
  inject('parseDate', parseDate);
};
