import { error } from '@/mixins/apollo';

import GET_POA_STATUS from '~/graphql/queries/GetPoaStatus';

function getPoaStatus() {
  return {
    query: GET_POA_STATUS,
    update: (data) => {
      return data.poa !== null ? data.poa.status : null;
    },
    error,
  };
}

const queries = {
  getPoaStatus,
};

export { queries };
