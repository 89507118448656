import { getField, updateField } from 'vuex-map-fields';

import { metaArrayToObject } from '~/utilities';

function defaults(o = {}) {
  return Object.assign(
    {
      description: '',
      type: '',
      amount: '',
      note: '',
    },
    o
  );
}

export const state = () => ({
  id: null,
  fields: defaults(),
  person: null,
  charity: null,
  showGiftWizard: false,
});

export const getters = {
  id: (state) => state.id,
  fields: (state) => state.fields,
  person: (state) => state.person,
  charity: (state) => state.charity,
  showGiftWizard: (state) => state.showGiftWizard,
  getField,
};

export const mutations = {
  id(state, value) {
    state.id = value;
  },
  fields(state, value) {
    state.fields = value;
  },
  person(state, value) {
    state.person = value;
  },
  charity(state, value) {
    state.charity = value;
  },
  showGiftWizard(state, value) {
    state.showGiftWizard = value;
  },
  updateField,
};

export const actions = {
  addGift({ commit }) {
    commit('id', null);
    commit('fields', defaults());
    commit('person', []);
    commit('charity', []);
    commit('showGiftWizard', true);
  },
  editGift({ commit }, gift) {
    commit('id', gift.id);
    commit('fields', defaults(metaArrayToObject(gift.meta)));

    if (gift.person) {
      commit('person', gift.person);
    } else if (gift.charity) {
      commit('charity', gift.charity);
    }

    commit('showGiftWizard', true);
  },
  setShowGiftWizard({ commit }, value) {
    commit('showGiftWizard', value);
  },
};
