import { createHelpers } from 'vuex-map-fields';
import { metaArrayToObject } from '~/utilities';

const { getCouponField, updateCouponField } = createHelpers({
  getterType: 'getCouponField',
  mutationType: 'updateCouponField',
});

function defaults(o = {}) {
  return Object.assign(
    {
      code: '',
      type: 'PERCENTAGE',
      description: '',
      factor: 0,
      active: false,
    },
    o
  );
}

export const state = () => ({
  id: null,
  fields: defaults(),
  showCouponModal: false,
});

export const getters = {
  id: (state) => state.id,
  fields: (state) => state.fields,
  showCouponModal: (state) => state.showCouponModal,
  getCouponField,
};

export const mutations = {
  id(state, value) {
    state.id = value;
  },
  fields(state, value) {
    state.fields = value;
  },
  showCouponModal(state, value) {
    state.showCouponModal = value;
  },
  updateCouponField(state, field) {
    if (field.path === 'fields.factor') {
      field.value = parseInt(field.value) || 0;
    }
    if (field.path === 'code') {
      field.code = field.code.toUpperCase();
    }
    updateCouponField(state, field);
  },
};

export const actions = {
  addCoupon({ commit }) {
    commit('fields', defaults());
  },
  editCoupon({ commit }, coupon) {
    commit('id', coupon.id);
    commit('fields', defaults(metaArrayToObject(coupon.meta)));
  },
  setShowCouponModal({ commit }, value) {
    commit('fields', defaults());
    commit('showCouponModal', value);
  },
};
