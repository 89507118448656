import GET_INVITES_QUERY from '~/graphql/queries/GetInvites';
import GET_PUBLIC_INVITE_QUERY from '~/graphql/queries/GetPublicInvite';

import { error } from '~/mixins/apollo';

export default {
  apollo: {
    invites: {
      query: GET_INVITES_QUERY,
      variables() {
        return {
          willId: this.willId,
        };
      },
      update: (data) => data.getInvites && data.getInvites.invites,
      skip() {
        return !this.token;
      },
      error,
    },
    publicInvite: {
      query: GET_PUBLIC_INVITE_QUERY,
      variables() {
        return {
          willId: this.willId,
        };
      },
      update: (data) => data.getPublicInvite && data.getPublicInvite.invite,
      skip() {
        return !this.token;
      },
      error,
    },
  },
  computed: {
    getInvitesQuery() {
      return {
        query: GET_INVITES_QUERY,
        variables: {
          willId: this.willId,
        },
      };
    },
    invitedBy() {
      return this.will?.invite?.user?.email ?? 'N/A';
    },
    completeInvites() {
      return this.will?.invites?.filter(
        (invite) => invite.purchased && invite.type !== 'PUBLIC'
      ).length;
    },
  },
  data() {
    return {
      invites: [],
    };
  },
};
