export const state = () => ({
  showGlossaryPanel: false,
  terms: {
    Beneficiary:
      'A person receiving a gift or portion of the estate under a Will.',
    Bequest: 'A gift of money or property to a charity organisation.',
    Child: 'A biological or adopted child, but not a stepchild.',
    'De Facto/Domestic relationship':
      'A relationship where two people are not married, but are living with one another as partners. These relationships are defined and recognised under the law of each state/territory, and include same-sex couples.',
    Duress:
      'Undue pressure or influence from another person on you when writing your Will.',
    'Estate / Residual estate':
      'Everything that is left over after all debts, funeral and testamentary expenses, and gifts are provided for.',
    'Estate Administration':
      'The act of carrying out your Will, performed by an Executor. You can choose a trusted friend or family member to act as your Executor, or a Safewill Professional Executor.',
    Executor:
      'The person nominated under your Will to administer your estate when you die.',
    Gift:
      'A specific asset or sum of money that you choose to leave to a person or organisation.',
    'Grant of probate':
      'A certificate issued by the Court to the executor, allowing them to administer the estate.',
    Guardian:
      'The person you appoint to be legally responsible and care for your children or pets. Note that pet guardianship is non-binding.',
    Intestate: 'This means dying without a Will.',
    Minor: 'A child under the age of 18.',
    'Testamentary capacity': 'Mental capability to make a Will.',
    Testator: 'The person who is making the Will.',
    Trustee:
      'A person or organisation who holds property for another on trust.',
  },
});

export const getters = {
  showGlossaryPanel: (state) => state.showGlossaryPanel,
  terms: (state) => state.terms,
};

export const mutations = {
  showGlossaryPanel(state, value) {
    state.showGlossaryPanel = value;
  },
};

export const actions = {
  setShowGlossaryPanel({ commit }, value) {
    commit('showGlossaryPanel', value);
  },
};
