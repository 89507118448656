import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';

export default function({ redirect, $config }) {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        if (error.message === 'Not Authorised!') {
          redirect('/log-out');
        }
      });
    }
  });

  return {
    httpEndpoint: process.env.APOLLO_HTTP_ENDPOINT,
    cache: new InMemoryCache(),
    link: ApolloLink.from([errorLink]),
    httpLinkOptions: {
      credentials: 'include',
    },
  };
}
