import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'userId',
      'token',
      'willId',
      'willStatus',
      'masquerading',
      'role',
    ]),
  },
};
