import GET_SUBSCRIPTION_V2_QUERY from '~/graphql/queries/GetSubscriptionV2';

import { error, user } from '~/mixins/apollo';

export default {
  mixins: [user],
  apollo: {
    subscriptionV2: {
      query: GET_SUBSCRIPTION_V2_QUERY,
      variables() {
        return {
          userId: this.userId,
        };
      },
      fetchPolicy: 'no-cache',
      update: (data) => data.getSubscriptionV2,
      skip() {
        return !this.token || !this.userId;
      },
      error,
    },
  },
  data() {
    return {
      subscriptionV2: null,
    };
  },
  computed: {
    formattedSubscriptionExpiryDate() {
      if (!this.subscriptionV2.expiresAt) return '';
      return this.$formatDate(this.subscriptionV2.expiresAt, 'dS mmmm yyyy');
    },
    formattedSubscriptionPaymentMethodExpiryDate() {
      if (!this.subscriptionV2.paymentMethodExpiresAt) return '';
      return this.$formatDate(
        this.subscriptionV2.paymentMethodExpiresAt,
        'dS mmmm yyyy'
      );
    },
    formattedSubscriptionCreatedAtDate() {
      if (!this.subscriptionV2.createdAt) return '';
      return this.$formatDate(this.subscriptionV2.createdAt, 'dS mmmm yyyy');
    },
  },
};
