const PRICE_DEFAULTS = {
  DEFAULT_SETUP_PRICE: 160,
  DEFAULT_PARTNER_PRICE: 80,
  DEFAULT_SUBSCRIPTION_PRICE: 15,
  // DEFAULT_UNLOCKING_PRICE is supposed to be half of DEFAULT_SETUP_PRICE.
  // It should be updated when DEFAULT_SETUP_PRICE changes.
  DEFAULT_UNLOCKING_PRICE: 80,
};

export const state = () => ({
  checkoutAction: null,
  defaultSetupPrice: PRICE_DEFAULTS.DEFAULT_SETUP_PRICE,
  defaultPartnerPrice: PRICE_DEFAULTS.DEFAULT_PARTNER_PRICE,
  finalSetupPrice: null,
  defaultSubscriptionPrice: PRICE_DEFAULTS.DEFAULT_SUBSCRIPTION_PRICE,
  defaultUnlockingPrice: PRICE_DEFAULTS.DEFAULT_UNLOCKING_PRICE,
  couponCode: null,
  invitedByPartner: false,
  paymentMethod: 'stripe',
  paymentProcessing: false,
  paymentLoading: false,
  paymentSuccess: false,
  paymentError: null,
});

export const getters = {
  checkoutAction: (state) => state.checkoutAction,
  defaultSetupPrice: (state) => state.defaultSetupPrice,
  defaultPartnerPrice: (state) => state.defaultPartnerPrice,
  finalSetupPrice: (state) => state.finalSetupPrice,
  defaultSubscriptionPrice: (state) => state.defaultSubscriptionPrice,
  defaultUnlockingPrice: (state) => state.defaultUnlockingPrice,
  couponCode: (state) => state.couponCode,
  invitedByPartner: (state) => state.invitedByPartner,
  paymentMethod: (state) => state.paymentMethod,
  paymentProcessing: (state) => state.paymentProcessing,
  paymentLoading: (state) => state.paymentLoading,
  paymentSuccess: (state) => state.paymentSuccess,
  paymentError: (state) => state.paymentError,
};

export const mutations = {
  checkoutAction(state, value) {
    state.checkoutAction = value;
  },
  setFinalSetupPrice(state, value) {
    state.finalSetupPrice = value;
  },
  setCouponCode(state, value) {
    state.couponCode = value;
  },
  setInvitedByPartner(state, value) {
    state.invitedByPartner = value;
  },
  setPaymentMethod(state, value) {
    state.paymentMethod = value;
  },
  setPaymentProcessing(state, value) {
    state.paymentProcessing = value;
  },
  setPaymentLoading(state, value) {
    state.paymentLoading = value;
  },
  setPaymentSuccess(state, value) {
    state.paymentSuccess = value;
  },
  setPaymentError(state, value) {
    state.paymentError = value;
  },
  setDefaultUnlockingPrice(state, value) {
    state.defaultUnlockingPrice = value;
  },
};

export const actions = {
  setShowCheckoutModal({ commit }, value) {
    commit('showCheckoutModal', value);
  },
  renewSubscription({ commit }) {
    commit('checkoutAction', 'renew');
    commit('setDefaultUnlockingPrice', PRICE_DEFAULTS.DEFAULT_UNLOCKING_PRICE);
  },
  renewRecentSubscription({ commit }) {
    commit('checkoutAction', 'renew');
    commit(
      'setDefaultUnlockingPrice',
      PRICE_DEFAULTS.DEFAULT_SUBSCRIPTION_PRICE
    );
  },
  submitWill({ commit }) {
    commit('checkoutAction', 'submit');
  },
  updateCardDetails({ commit }) {
    commit('checkoutAction', 'update');
  },
  afterpayApproved({ commit }) {
    commit('checkoutAction', 'submit');
    commit('setPaymentMethod', 'afterpay');
  },
  zipMoneyApproved({ commit }) {
    commit('checkoutAction', 'submit');
    commit('setPaymentMethod', 'zip');
  },
  setFinalSetupPrice({ commit }, value) {
    commit('setFinalSetupPrice', value);
  },
  setCouponCode({ commit }, value) {
    commit('setCouponCode', value);
  },
  setInvitedByPartner({ commit }, value) {
    commit('setInvitedByPartner', value);
  },
  setPaymentMethod({ commit }, value) {
    commit('setPaymentMethod', value);
  },
  setPaymentProcessing({ commit }, value) {
    commit('setPaymentProcessing', value);
  },
  setPaymentLoading({ commit }, value) {
    commit('setPaymentLoading', value);
  },
  setPaymentSuccess({ commit }, value) {
    commit('setPaymentSuccess', value);
  },
  setPaymentError({ commit }, value) {
    commit('setPaymentError', value);
  },
  resetCheckoutState({ commit }) {
    commit('setCheckoutAction', null);
    commit('setDefaultSetupPrice', PRICE_DEFAULTS.DEFAULT_SETUP_PRICE);
    commit('setDefaultPartnerPrice', PRICE_DEFAULTS.DEFAULT_PARTNER_PRICE);
    commit('setFinalSetupPrice', null);
    commit(
      'setDefaultSubscriptionPrice',
      PRICE_DEFAULTS.DEFAULT_SUBSCRIPTION_PRICE
    );
    commit('setDefaultUnlockingPrice', PRICE_DEFAULTS.DEFAULT_UNLOCKING_PRICE);
    commit('setCouponCode', null);
    commit('setInvitedByPartner', false);
    commit('setPaymentMethod', 'stripe');
    commit('setPaymentProcessing', false);
    commit('setPaymentLoading', false);
    commit('setPaymentSuccess', false);
    commit('setPaymentError', null);
  },
};
