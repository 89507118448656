import { error } from '@/mixins/apollo';
import GET_PEOPLE from '~/graphql/queries/GetPeopleOfAccount.gql';

function getPeopleOfAccount() {
  return {
    query: GET_PEOPLE,
    update(data) {
      return data.getPeopleOfAccount.people;
    },
    error,
  };
}

const queries = {
  getPeopleOfAccount,
};

export { queries };
