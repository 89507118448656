import { mapActions, mapGetters } from 'vuex';

import GET_COUPONS_QUERY from '~/graphql/queries/GetCoupons';
import ADD_COUPON_MUTATION from '~/graphql/mutations/AddCoupon';
import REMOVE_COUPON_MUTATION from '~/graphql/mutations/RemoveCoupon';
import UPDATE_COUPON_MUTATION from '~/graphql/mutations/UpdateCoupon';
import ARCHIVE_COUPON_MUTATION from '~/graphql/mutations/ArchiveCoupon';
import { metaArrayToObject } from '~/utilities';
import { error } from '~/mixins/apollo';

export default {
  computed: {
    ...mapGetters('admin', ['couponsPageIndex']),
    getCouponsQuery() {
      return {
        query: GET_COUPONS_QUERY,
        variables: {
          searchQuery: this.couponSearch,
          limit: this.pageSize,
          offset: this.couponsPageIndex * this.pageSize,
          sort: this.couponsSortOrder,
        },
        update(data) {
          if (data.getCoupons) {
            data.getCoupons.coupons.map((coupon) => {
              coupon.meta = metaArrayToObject(coupon.meta);
            });
            return data.getCoupons.coupons;
          }
        },
      };
    },
  },
  data() {
    return {
      ADD_COUPON_MUTATION,
      REMOVE_COUPON_MUTATION,
      UPDATE_COUPON_MUTATION,
      ARCHIVE_COUPON_MUTATION,
      coupons: [],
      pageSize: 10,
    };
  },
  methods: {
    ...mapActions('coupon', ['addCoupon', 'editCoupon']),
    async updateCoupon(coupon) {
      await this.$apollo
        .mutate({
          mutation: this.UPDATE_COUPON_MUTATION,
          variables: {
            ...coupon,
          },
          update: (store) => {
            const data = store.readQuery(this.getCouponsQuery);
            const index = data.getCoupons.coupons.findIndex(
              (m) => m.id === coupon.id
            );

            if (index !== -1) {
              data.getCoupons.coupons.splice(index, 1, coupon);

              store.writeQuery({
                ...this.getCouponsQuery,
                data,
              });
            }
          },
        })
        .catch(error);
    },
    async removeCoupon(coupon) {
      await this.$apollo
        .mutate({
          mutation: this.REMOVE_COUPON_MUTATION,
          variables: {
            id: coupon.id,
          },
          update: (store) => {
            const data = store.readQuery(this.getCouponsQuery);
            const index = data.getCoupons.coupons.findIndex(
              (m) => m.id === coupon.id
            );

            if (index !== -1) {
              data.getCoupons.coupons.splice(index, 1);

              store.writeQuery({
                ...this.getCouponsQuery,
                data,
              });
            }
          },
        })
        .catch(error);
    },
    async archiveCoupon(coupon) {
      await this.$apollo
        .mutate({
          mutation: this.ARCHIVE_COUPON_MUTATION,
          variables: {
            id: coupon.id,
          },
          update: (store) => {
            const data = store.readQuery(this.getCouponsQuery);
            const index = data.getCoupons.coupons.findIndex(
              (m) => m.id === coupon.id
            );

            if (index !== -1) {
              data.getCoupons.coupons.splice(index, 1);

              store.writeQuery({
                ...this.getCouponsQuery,
                data,
              });
            }
          },
        })
        .catch(error);
    },
  },
};
