import CANCEL_SUBSCRIPTION_MUTATION from '~/graphql/mutations/CancelSubscription';
import GET_SUBSCRIPTION_QUERY from '~/graphql/queries/GetSubscription';
import REACTIVATE_SUBSCRIPTION_MUTATION from '~/graphql/mutations/ReactivateSubscription';
import UPDATE_SUBSCRIPTION_MUTATION from '~/graphql/mutations/UpdateSubscription';

import { error, user } from '~/mixins/apollo';

export default {
  mixins: [user],
  apollo: {
    subscription: {
      query: GET_SUBSCRIPTION_QUERY,
      variables() {
        return {
          userId: this.userId,
        };
      },
      fetchPolicy: 'no-cache',
      update: (data) =>
        data.getSubscription &&
        data.getSubscription.account &&
        data.getSubscription.account.subscription,
      skip() {
        return !this.token || !this.userId;
      },
      error,
    },
  },
  data() {
    return {
      subscription: null,
    };
  },
  computed: {
    hasExpiredSubscription() {
      return this.subscription && !this.subscription.valid;
    },
    hasValidSubscription() {
      return this.subscription && this.subscription.valid;
    },
    showUpdateSubscriptionBanner() {
      return this.subscription && this.subscription.expireAtPeriodEnd;
    },
    hasStripePaymentMethod() {
      return this.subscription && this.subscription.hasStripePaymentMethod;
    },
    formattedExpiryDate() {
      if (!this.subscription.currentPeriodEnd) return '';
      return this.$formatDate(
        this.subscription.currentPeriodEnd,
        'dS mmmm yyyy'
      );
    },
  },
  methods: {
    async cancelSubscription() {
      const response = await this.$apollo
        .mutate({
          mutation: CANCEL_SUBSCRIPTION_MUTATION,
          variables: {
            userId: this.userId,
          },
        })
        .then((res) => {
          this.$nuxt.$emit('sendTrackingEvent', {
            event: '⏸ Pause Subscription',
          });
          this.$apollo.queries.subscription.refetch();

          return res;
        });

      return response;
    },
    async reactivateSubscription(
      couponCode = null,
      zipCheckoutId = null,
      afterpayPaymentToken = null
    ) {
      const response = await this.$apollo
        .mutate({
          mutation: REACTIVATE_SUBSCRIPTION_MUTATION,
          variables: {
            userId: this.userId,
            coupon: couponCode,
            zipCheckoutId,
            afterpayPaymentToken,
          },
        })
        .then((res) => {
          this.$nuxt.$emit('sendTrackingEvent', {
            event: '▶️ Resume Subscription',
          });
          this.$apollo.queries.subscription.refetch();

          return res;
        });

      return response;
    },
    async updateSubscription(cardToken) {
      const response = await this.$apollo.mutate({
        mutation: UPDATE_SUBSCRIPTION_MUTATION,
        variables: {
          userId: this.userId,
          cardToken,
        },
      });

      return response;
    },
  },
};
