import { removeLegacyCookie } from '@/modules/cookies';
import { getUserFullName, metaArrayToObject } from '../utilities';

const { name, version } = require('../package.json');
const CURRENT_USER_QUERY = require('~/graphql/queries/CurrentUser');
const GET_WILL_QUERY = require('~/graphql/queries/GetWill');

const init = async ({ app, redirect, store: { state, commit, dispatch } }) => {
  console.info(
    `${name} v${version} (${process.env.NODE_ENV}) \n${process.env.BRANCH} (${process.env.COMMIT_REF})`
  );
  const token = app.$apolloHelpers.getToken();
  if (token) {
    const client = app.apolloProvider.defaultClient;
    try {
      const { data } = await client.query({ query: CURRENT_USER_QUERY });
      if (data.currentUser.user) {
        commit('setEmail', data.currentUser.user.email);
        commit('setRole', data.currentUser.user.role);
        commit('setUserId', data.currentUser.user.id);
        commit('setVerified', data.currentUser.user.verified);
        commit('setMasquerading', false);
        commit('setToken', token);
      } else {
        removeLegacyCookie();
        app.$apolloHelpers.onLogout();
        dispatch('resetUserState');
        dispatch('resetCheckoutState');
        redirect('/log-in');
      }
    } catch (e) {
      removeLegacyCookie();
      app.$apolloHelpers.onLogout();
      console.error(e.message);
      redirect('/log-in');
    }

    try {
      if (state.role === 'CONSUMER') {
        const { data } = await client.query({ query: GET_WILL_QUERY });
        if (data.getWill.will) {
          dispatch('setWillId', data.getWill.will.id);
          dispatch('setWillStatus', data.getWill.will.status);
          const zendeskUser = {
            name: getUserFullName(
              metaArrayToObject(data.getWill.will.meta || [])
            ),
            email: state.email,
          };
          if (window.zE) {
            window.zE('webWidget', 'identify', zendeskUser);
          }
        }
      }
    } catch (e) {
      console.error(e.message);
    }
  }
};

export default init;
