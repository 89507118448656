export default function({ app, redirect, store }) {
  const hasToken = !!app.$apolloHelpers.getToken();

  if (hasToken) {
    if (!['MODERATOR', 'ADMIN', 'SUPER_ADMIN'].includes(store.state.role)) {
      return redirect('/log-in');
    }
  } else {
    return redirect('/log-in');
  }
}
