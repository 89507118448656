import ADD_LIABILITY_MUTATION from '~/graphql/mutations/AddLiability';
import GET_LIABILITIES_QUERY from '~/graphql/queries/GetLiabilities';
import REMOVE_LIABILITY_MUTATION from '~/graphql/mutations/RemoveLiability';
import UPDATE_LIABILITY_MUTATION from '~/graphql/mutations/UpdateLiability';

import { error } from '~/mixins/apollo';

export default {
  apollo: {
    liabilities: {
      query: GET_LIABILITIES_QUERY,
      variables() {
        return {
          willId: this.willId,
        };
      },
      update: (data) => data.getLiabilities && data.getLiabilities.liabilities,
      skip() {
        return !this.token || !this.willId;
      },
      error,
    },
  },
  data() {
    return {
      liabilities: [],
    };
  },
  computed: {
    getLiabilitiesQuery() {
      return {
        query: GET_LIABILITIES_QUERY,
        variables: {
          willId: this.willId,
        },
      };
    },
  },
  methods: {
    isLiability(liability) {
      return !!this.liabilities.find((a) => a.id === liability.id);
    },
    async addLiability(meta) {
      await this.$apollo
        .mutate({
          mutation: ADD_LIABILITY_MUTATION,
          variables: {
            willId: this.willId,
            meta,
          },
          update: (store, { data: { addLiability } }) => {
            const data = store.readQuery(this.getLiabilitiesQuery);
            data.getLiabilities.liabilities.push(addLiability.liability);

            store.writeQuery({
              ...this.getLiabilitiesQuery,
              data,
            });
          },
        })
        .catch(error);
    },
    async removeLiability(liability) {
      await this.$apollo
        .mutate({
          mutation: REMOVE_LIABILITY_MUTATION,
          variables: {
            id: liability.id,
            willId: this.willId,
          },
          update: (store) => {
            const data = store.readQuery(this.getLiabilitiesQuery);
            const index = data.getLiabilities.liabilities.findIndex(
              (m) => m.id === liability.id
            );

            if (index !== -1) {
              data.getLiabilities.liabilities.splice(index, 1);

              store.writeQuery({
                ...this.getLiabilitiesQuery,
                data,
              });
            }
          },
        })
        .catch(error);
    },
    async updateLiability(liability, meta) {
      await this.$apollo
        .mutate({
          mutation: UPDATE_LIABILITY_MUTATION,
          variables: {
            id: liability.id,
            willId: this.willId,
            meta,
          },
        })
        .catch(error);
    },
  },
};
