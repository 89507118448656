
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AccountSubscription"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AccountSubscription"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"stripeId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"zipUserId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"afterpayPaymentId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"currentPeriodEnd"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"expireAtPeriodEnd"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"hasStripePaymentMethod"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"valid"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":171}};
    doc.loc.source = {"body":"fragment AccountSubscription on AccountSubscription {\n  stripeId\n  zipUserId\n  afterpayPaymentId\n  currentPeriodEnd\n  expireAtPeriodEnd\n  hasStripePaymentMethod\n  valid\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
