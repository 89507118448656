export function isSameCharity(charity1, charity2) {
  return (
    !!charity1.name &&
    charity1.name === charity2.name &&
    !!charity1.address &&
    charity1.address === charity2.address &&
    !!charity1.abn &&
    charity1.abn === charity2.abn
  );
}

export function localCharityData(userState, charityKey, referralCharities) {
  const hasRegionData =
    userState &&
    referralCharities[charityKey].regions &&
    referralCharities[charityKey].regions[userState];
  return hasRegionData
    ? { ...referralCharities[charityKey].regions[userState] }
    : { ...referralCharities[charityKey] };
}
