import { mapActions } from 'vuex';

import ADD_PET_GUARDIAN_MUTATION from '~/graphql/mutations/AddPetGuardian';
import ADD_PET_MUTATION from '~/graphql/mutations/AddPet';
import GET_PETS_QUERY from '~/graphql/queries/GetPets';
import REMOVE_PET_GUARDIAN_MUTATION from '~/graphql/mutations/RemovePetGuardian';
import REMOVE_PET_MUTATION from '~/graphql/mutations/RemovePet';
import UPDATE_PET_MUTATION from '~/graphql/mutations/UpdatePet';

import { error } from '~/mixins/apollo';

export default {
  apollo: {
    pets: {
      query: GET_PETS_QUERY,
      variables() {
        return {
          willId: this.willId,
        };
      },
      update: (data) => data.getPets && data.getPets.pets,
      skip() {
        return !this.token || !this.willId;
      },
      error,
    },
  },
  data() {
    return {
      ADD_PET_GUARDIAN_MUTATION,
      ADD_PET_MUTATION,
      REMOVE_PET_GUARDIAN_MUTATION,
      REMOVE_PET_MUTATION,
      UPDATE_PET_MUTATION,
      pets: [],
    };
  },
  computed: {
    getPetsQuery() {
      return {
        query: GET_PETS_QUERY,
        variables: {
          willId: this.willId,
        },
      };
    },
  },
  methods: {
    ...mapActions('pet', ['addPet', 'editPet']),
    async addPetGuardian(pet, personId) {
      await this.$apollo
        .mutate({
          mutation: this.ADD_PET_GUARDIAN_MUTATION,
          variables: {
            petId: pet.id,
            personId,
            willId: this.willId,
          },
        })
        .catch(error);
    },
    isPetGuardian(person) {
      return !!(
        this.pets &&
        this.pets.find((pet) => pet.person && pet.person.id === person.id)
      );
    },
    async removeAllPets() {
      await Promise.all(
        this.pets.map((pet) => {
          return this.removePet(pet);
        })
      );
    },
    async removePet(pet) {
      await this.$apollo
        .mutate({
          mutation: this.REMOVE_PET_MUTATION,
          variables: {
            id: pet.id,
            willId: this.willId,
          },
          update: (store) => {
            const data = store.readQuery(this.getPetsQuery);
            const index = data.getPets.pets.findIndex((m) => m.id === pet.id);

            if (index !== -1) {
              data.getPets.pets.splice(index, 1);

              store.writeQuery({
                ...this.getPetsQuery,
                data,
              });
            }
          },
        })
        .catch(error);
    },
    async removePetGuardian(pet) {
      await this.$apollo
        .mutate({
          mutation: this.REMOVE_PET_GUARDIAN_MUTATION,
          variables: {
            petId: pet.id,
            willId: this.willId,
          },
        })
        .catch(error);
    },
    async updatePet(pet, variables) {
      await this.$apollo
        .mutate({
          mutation: this.UPDATE_PET_MUTATION,
          variables: Object.assign(variables, {
            id: pet.id,
            willId: this.willId,
          }),
        })
        .catch(error);
    },
  },
};
