import { mapActions } from 'vuex';

export const renewSubscriptionHandler = {
  methods: {
    ...mapActions('checkout', ['renewSubscription', 'renewRecentSubscription']),
    handleRenewSubscription() {
      const currentPeriodEnd = new Date(this.subscription.currentPeriodEnd);
      const recentSubscriptionPeriodEnd = new Date(currentPeriodEnd).setMonth(
        currentPeriodEnd.getMonth() + 1
      );
      if (Date.now() <= recentSubscriptionPeriodEnd) {
        this.renewRecentSubscription().then(() =>
          this.$router.push({ path: '/checkout' })
        );
      } else {
        this.renewSubscription().then(() =>
          this.$router.push({ path: '/checkout' })
        );
      }
    },
  },
};
