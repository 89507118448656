export default function({ redirect, store }) {
  if (
    store.state.token &&
    ['MODERATOR', 'ADMIN', 'SUPER_ADMIN'].includes(store.state.role)
  ) {
    return redirect('/admin/submissions');
  } else {
    return redirect('/log-in');
  }
}
