import { metaArrayToObject } from '~/utilities';

// <MetaSlot
//   v-slot="{ foo, bar }"
//   :foo="fooArray"
//   :bar="barArray"
// >
//   <Component
//     :title="foo.title"
//     :count="bar.count"
//   />
// </MetaSlot>

export default {
  functional: true,
  render: (h, { data: { scopedSlots }, props }) => {
    const metaProps = Object.assign({}, props);

    Object.keys(metaProps).forEach((key) => {
      metaProps[key] = metaArrayToObject(metaProps[key]);
    });

    return scopedSlots.default(metaProps);
  },
};
