const willSortableFields = {
  updatedAt: 'updatedAt',
  awaitingOn: 'awaitingOn',
};

const sortOrderValues = {
  ascending: 'ASC',
  descending: 'DESC',
};

export const state = () => ({
  debug: false,
  submissionsPageIndex: 0,
  submissionsStatusFilter: null,
  submissionsSearch: '',
  couponsPageIndex: 0,
  usersPageIndex: 0,
  usersPageSearch: '',
  customOrderDirection: sortOrderValues.descending,
  customOrderField: willSortableFields.updatedAt,
  couponSearch: '',
  couponsSortOrder: sortOrderValues.descending,
  userRoleFilter: null,
});

export const getters = {
  debug: (state) => state.debug,
  submissionsPageIndex: (state) => state.submissionsPageIndex,
  submissionsStatusFilter: (state) => state.submissionsStatusFilter,
  submissionsSearch: (state) => state.submissionsSearch,
  couponsPageIndex: (state) => state.couponsPageIndex,
  usersPageIndex: (state) => state.usersPageIndex,
  usersPageSearch: (state) => state.usersPageSearch,
  couponSearch: (state) => state.couponSearch,
  couponsSortOrder: (state) => state.couponsSortOrder,
  userRoleFilter: (state) => state.userRoleFilter,
};

export const mutations = {
  toggleDebug(state) {
    state.debug = !state.debug;
  },
  decrementSubmissionsPageIndex(state) {
    state.submissionsPageIndex--;
  },
  incrementSubmissionsPageIndex(state) {
    state.submissionsPageIndex++;
  },
  submissionsPageIndex(state, value) {
    state.submissionsPageIndex = value;
  },
  submissionsStatusFilter(state, value) {
    state.submissionsStatusFilter = value;
  },
  submissionsSearch(state, value) {
    state.submissionsSearch = value;
  },
  decrementCouponsPageIndex(state) {
    state.couponsPageIndex--;
  },
  incrementCouponsPageIndex(state) {
    state.couponsPageIndex++;
  },
  couponsPageIndex(state, value) {
    state.couponsPageIndex = value;
  },
  incrementUsersPageIndex(state) {
    state.usersPageIndex++;
  },
  decrementUsersPageIndex(state) {
    state.usersPageIndex--;
  },
  setUsersPageIndex(state, value) {
    state.usersPageIndex = value;
  },
  setUsersPageSearch(state, value) {
    state.usersPageSearch = value;
  },
  setSortOrder(state, { field, direction }) {
    state.customOrderDirection = direction;
    state.customOrderField = field;
    state.submissionsPageIndex = 0;
  },
  setCouponSearch(state, value) {
    state.couponSearch = value;
  },
  toggleCouponSortOrder(state) {
    state.couponsSortOrder =
      state.couponsSortOrder === sortOrderValues.ascending
        ? sortOrderValues.descending
        : sortOrderValues.ascending;
    state.submissionsPageIndex = 0;
  },
  userRoleFilter(state, value) {
    state.userRoleFilter = value;
  },
};

export const actions = {
  toggleDebug({ commit }) {
    commit('toggleDebug');
  },
  nextSubmissionsPage({ commit }) {
    commit('incrementSubmissionsPageIndex');
  },
  prevSubmissionsPage({ commit }) {
    commit('decrementSubmissionsPageIndex');
  },
  resetSubmissionsPageIndex({ commit }) {
    commit('submissionsPageIndex', 0);
  },
  setSubmissionsStatusFilter({ commit }, value) {
    commit('submissionsStatusFilter', value);
  },
  setSubmissionsSearch({ commit }, value) {
    commit('submissionsSearch', value);
  },
  nextCouponsPage({ commit }) {
    commit('incrementCouponsPageIndex');
  },
  prevCouponsPage({ commit }) {
    commit('decrementCouponsPageIndex');
  },
  resetCouponsPageIndex({ commit }) {
    commit('couponsPageIndex', 0);
  },
  nextUsersPage({ commit }) {
    commit('incrementUsersPageIndex');
  },
  prevUsersPage({ commit }) {
    commit('decrementUsersPageIndex');
  },
  resetUsersPageIndex({ commit }) {
    commit('setUsersPageIndex', 0);
  },
  setUsersPageSearch({ commit }, value) {
    commit('setUsersPageSearch', value);
  },
  setSortOrder({ commit }, value) {
    commit('setSortOrder', value);
  },
  setCouponSearch({ commit }, value) {
    commit('setCouponSearch', value);
  },
  toggleCouponSortOrder({ commit }) {
    commit('toggleCouponSortOrder');
  },
  setUserRoleFilter({ commit }, value) {
    commit('userRoleFilter', value);
  },
};
