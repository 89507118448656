const referralCodesToCharities = {
  swt: ['swt', 'rspca'],
  fhf: ['fhf'],
  plan: ['plan'],
  cpa: ['cpa'],
  oxfam: ['oxfam'],
  cancercouncil: ['cancercouncil'],
  ccv: ['ccv'],
  ccnsw: ['ccnsw'],
  cancercouncilwa: ['cancercouncilwa'],
  hroz: ['hroz'],
  clcrf: ['clcrf'],
  guidedogswa: ['guidedogswa'],
  asthma: ['asthma'],
  ms: ['ms'],
  schf: ['schf'],
  ifaw: ['ifaw'],
  cbc: ['cbc'],
  charlies: ['charlies'],
  curecancer: ['curecancer'],
  bowelcancer: ['bowelcancer'],
  nsf: ['nsf'],
  deakin: ['deakin'],
  tbbf: ['tbbf'],
  msra: ['msra'],
  stca: ['stca'],
  msf: ['msf'],
  tsc: ['tsc'],
  mswa: ['mswa'],
  diabetesvic: ['diabetesvic'],
  aarf: ['aarf'],
  tai: ['tai'],
  asrc: ['asrc'],
  lfa: ['lfa'],
  camp: ['camp'],
  gsjmf: ['gsjmf'],
  kha: ['kha'],
  ywca: ['ywca'],
  wildlifevic: ['wildlifevic'],
  slsf: ['slsf'],
  youngcare: ['youngcare'],
  swans: ['swans'],
  care: ['care'],
  canteen: ['canteen'],
  cws: ['cws'],
  gda: ['gda'],
  lifeline: ['lifeline'],
  svdpwa: ['svdpwa'],
  nationaltrust: ['nationaltrust'],
  rspca: ['rspca'],
  ozharvest: ['ozharvest'],
  baker: ['baker'],
  svdpvic: ['svdpvic'],
  animalsaustralia: ['animalsaustralia'],
  ethiopiaid: ['ethiopiaid'],
  anzgog: ['anzgog'],
  ccia: ['ccia'],
  cmri: ['cmri'],
  aurora: ['aurora'],
  redr: ['redr'],
  baptistmissionaustralia: ['baptistmissionaustralia'],
  nextsense: ['nextsense'],
  lei: ['lei'],
  leprosymission: ['leprosymission'],
  ina: ['ina'],
  unitingworld: ['unitingworld'],
  schoolforlife: ['schoolforlife'],
  greenpeace: ['greenpeace'],
  aspect: ['aspect'],
  rfw: ['rfw'],
  acon: ['acon'],
  nswliberal: ['nswliberal'],
  charitybounce: ['charitybounce'],
  awlnsw: ['awlnsw'],
  svdpnsw: ['svdpnsw'],
  wilderness: ['wilderness'],
  royalwomen: ['royalwomen'],
  caritas: ['caritas'],
  hudson: ['hudson'],
  catprotection: ['catprotection'],
  cathaven: ['cathaven'],
  rsb: ['rsb'],
  ncf: ['ncf'],
  rotaryfoundation: ['rotaryfoundation'],
  slswa: ['slswa'],
  centenaryinstitute: ['centenaryinstitute'],
  rawcs: ['rawcs'],
  wwf: ['wwf'],
  mwb: ['mwb'],
  petwelfare: ['petwelfare'],
  redcross: ['redcross'],
  amnesty: ['amnesty'],
  autismqld: ['autismqld'],
  habitat: ['habitat'],
  fullstop: ['fullstop'],
  cantoo: ['cantoo'],
  infantshome: ['infantshome'],
  epc: ['epc'],
  cbrt: ['cbrt'],
  vsk: ['vsk'],
  cpl: ['cpl'],
  ucq: [
    'ucq',
    'lifelinequeensland',
    'bluecare',
    'wesley',
    'standrews',
    'buderim',
    'ststephens',
    'arrcs',
  ],
  wellways: ['wellways'],
  mwf: ['mwf'],
  missionaustralia: ['missionaustralia'],
  wizovic: ['wizovic'],
  childhoodcancer: ['childhoodcancer'],
  materfoundation: ['materfoundation'],
  varietywa: ['varietywa'],
  orangesky: ['orangesky'],
  salvationarmy: ['salvationarmy'],
  guidedogsaus: ['guidedogsaus'],
  megavoice: ['megavoice'],
  lifeflight: ['lifeflight'],
};

export { referralCodesToCharities };
