export default function({ redirect, store, route, app }) {
  const hasToken = !!app.$apolloHelpers.getToken();

  if (
    ['MODERATOR', 'ADMIN', 'SUPER_ADMIN'].includes(store.state.role) &&
    !store.state.masquerading
  ) {
    if (!route.path.includes('/log-in')) {
      return redirect('/admin/submissions');
    }
  } else if (hasToken) {
    return redirect('/dashboard');
  }
}
