import { trySetLocalStorage } from '~/utilities';

const SHOW_SUGGESTED_CHARITIES_KEY = 'show-suggested-charities';
const SHOW_ZIP_KEY = 'show-zip';

export const FeatureFlagDefaults = {
  'power-of-attorney-frontend': true,
  [SHOW_SUGGESTED_CHARITIES_KEY]: true,
  [SHOW_ZIP_KEY]: false,
};

// This plugin will run on client side
// and will set the cookie if not exists
export default (ctx, inject) => {
  inject('ff', {
    powerOfAttorney: () =>
      ctx.store.state.featureFlags['power-of-attorney-frontend'],
    refreshFeatureFlags: () => {
      refreshFeatureFlags(ctx);
    },
    showZip: () => ctx.store.state.featureFlags[SHOW_ZIP_KEY],
  });

  // On the client side, right before all components begin to mount
  // Dispatch a call to set the feature flags store
  // Note that calling the API on client side may cause a "glitch"
  refreshFeatureFlags(ctx);
};

function refreshFeatureFlags(ctx) {
  if (typeof fetch === 'function') {
    fetch(`${process.env.SAFEWILL_BACKEND_API}/feature-flags`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Something went wrong');
        }
      })
      .then((result) => {
        ctx.store.commit('setFeatureFlags', result);

        // Set the local storage so that it loads faster next time
        for (const key in result) {
          trySetLocalStorage(key, result[key]);
        }
      })
      .catch(() => {
        console.error('Unable to refresh feature flags');
      });
  }
}
