import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import toString from 'lodash/toString';
import { config } from '@vue/test-utils';
import flushPromises from 'flush-promises';
import cookies from 'js-cookie';
import { v4 as uuid } from 'uuid';
import * as Sentry from '@sentry/browser';

export function metaArrayToObject(arr, defaults = {}) {
  if (isArray(arr)) {
    const obj = Object.assign({}, defaults);

    arr.forEach((item) => {
      let value = item.value;

      switch (value) {
        case 'true':
          value = true;
          break;
        case 'false':
          value = false;
          break;
        default:
          break;
      }

      obj[item.key] = value;
    });

    return obj;
  } else {
    return defaults;
  }
}

export function objectToMetaArray(obj = {}) {
  const arr = [];
  const optional = [
    'phone_number',
    'funeral_location',
    'funeral_note',
    'asset_note',
    'name_middle',
    'alt_name_middle',
    'pet_care_fund',
  ];

  Object.keys(obj).forEach((key) => {
    let value = obj[key];

    if (!isArray(value) && !isObject(value)) {
      if (value === null) {
        arr.push({
          key,
          value,
        });
      } else {
        value = toString(value);

        if (value.length) {
          arr.push({
            key,
            value,
          });
        } else if (optional.includes(key)) {
          arr.push({
            key,
            value: null,
          });
        }
      }
    }
  });

  return arr;
}

export function isIE11() {
  return window && window.navigator.appVersion.includes('Trident/');
}

export function formatError(message) {
  return message.replace('GraphQL error: ', '');
}

export function isValidEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export const f = (f) => {
  return f || '–';
};

export const yn = (b) => {
  let str;

  switch (b) {
    case true:
      str = 'Yes';
      break;
    case false:
      str = 'No';
      break;
    default:
      str = '–';
      break;
  }

  return str;
};

export const money = (s) => {
  return Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
  }).format(s);
};

export const date = (value) => {
  // https://stackoverflow.com/a/20773444/4605791
  const pattern = /(^(((0[1-9]|1[0-9]|2[0-8])[/](0[1-9]|1[012]))|((29|30|31)[/](0[13578]|1[02]))|((29|30)[/](0[4,6,9]|11)))[/](19|[2-9][0-9])\d\d$)|(^29[/]02[/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/gm;
  return pattern.test(value);
};

export const age = (dateOfBirth) => {
  const vals = dateOfBirth.split('/');
  const dob = new Date(vals[2], vals[1] - 1, vals[0]);
  const today = new Date();
  const birthday = new Date(today.getFullYear(), dob.getMonth(), dob.getDate());
  const hadBirthday = today >= birthday;
  const age = today.getFullYear() - dob.getFullYear();
  return hadBirthday ? age : age - 1;
};

export const flushAll = async () => {
  // Get rid of any pending validations on the leading edge
  await flushPromises();
  // Any delayed or debounced state computations
  jest.runAllTimers();
  // Get rid of the pending rendering tick
  await flushPromises();
};

export const clientOnly = () => {
  config.stubs['client-only'] = { template: '<div><slot /></div>' };
};

export const tryGetFromLocalStorage = (key, defaultValue = false) => {
  // Some browsers may error out if we access Local Storage
  try {
    const item = window.localStorage.getItem(key);

    if (item === null) {
      return defaultValue;
    }

    if (item === 'false') {
      return false;
    } else if (item === 'true') {
      return true;
    }

    return defaultValue;
  } catch (error) {
    return defaultValue;
  }
};

export const trySetLocalStorage = (key, value) => {
  try {
    window.localStorage.setItem(key, value);
  } catch (error) {
    // Do nothing
  }
};

export const getUserFullName = (willMetaObject) => {
  return [
    willMetaObject.name_first?.trim(),
    willMetaObject.name_middle?.trim(),
    willMetaObject.name_last?.trim(),
  ]
    .filter((name) => name)
    .join(' ');
};

export const getUserFullAddress = (willMetaObject) => {
  return [
    willMetaObject.address_street?.trim(),
    willMetaObject.address_suburb?.trim(),
    [
      willMetaObject.address_state?.trim(),
      willMetaObject.address_postcode?.trim(),
    ]
      .filter((part) => part)
      .join(' '),
  ]
    .filter((part) => part)
    .join(', ');
};

export const isBeneficiarySplitValid = (value) => {
  if (Number.isNaN(Number(value)) || Number(value) === 0) {
    return false;
  }

  const strValue = value.toString();

  if (strValue.startsWith('.')) {
    return false;
  }

  if ([...strValue].some((char) => ['-', '+', 'e', ','].includes(char))) {
    return false;
  }

  let [integer, decimal] = strValue
    .toString()
    .trim()
    .split('.');

  integer = integer || '';
  decimal = decimal || '';

  if (decimal.length > 2) {
    return false;
  }

  if (integer === '100') {
    if (!decimal || Number(decimal) === 0) {
      return true;
    }
  }

  return integer.length < 3;
};

export function jsonToBase64(json) {
  return Buffer.from(JSON.stringify(json)).toString('base64');
}

export function base64ToJson(base64) {
  return JSON.parse(Buffer.from(base64, 'base64').toString('ascii'));
}

export function getAnonymousProfile() {
  const anonymousProfileBase64 = cookies.get(
    process.env.ANONYMOUS_PROFILE_COOKIE_NAME || 'anonymousProfile'
  );
  if (!anonymousProfileBase64 || anonymousProfileBase64.trim().length === 0) {
    return null;
  }
  try {
    return base64ToJson(anonymousProfileBase64);
  } catch (e) {
    console.error(e.message);
  }
  return null;
}

export function saveAnonymousProfile({ utm, couponCode }) {
  const existingProfile = getAnonymousProfile() || {};

  cookies.set(
    process.env.ANONYMOUS_PROFILE_COOKIE_NAME || 'anonymousProfile',
    jsonToBase64({
      id: existingProfile.id || cookies.get('launchdarkly_id') || uuid(),
      utm: utm || existingProfile.utm || {},
      referral_coupon: couponCode || existingProfile.referral_coupon,
    }),
    {
      path: '/',
      domain: '.safewill.com',
      expires: 14,
    }
  );
}

export function getExperimentVariantValue({ experimentId, defaultValue }) {
  try {
    const variant = window.google_optimize.get(experimentId);
    if (variant === undefined || variant === null) {
      throw new Error(`Variant value is not defined: ${experimentId}`);
    }
    return variant;
  } catch (error) {
    error.message = error.message + `: ${experimentId}`;
    Sentry.captureException(error);
    return defaultValue;
  }
}
