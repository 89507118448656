import ADD_EXECUTOR_MUTATION from '~/graphql/mutations/AddExecutor';
import GET_EXECUTORS_QUERY from '~/graphql/queries/GetExecutors';
import REMOVE_EXECUTOR_MUTATION from '~/graphql/mutations/RemoveExecutor';

import { error } from '~/mixins/apollo';

export default {
  apollo: {
    executors: {
      prefetch: false,
      query: GET_EXECUTORS_QUERY,
      variables() {
        return {
          willId: this.willId,
        };
      },
      update: (data) => {
        return data.getExecutors && data.getExecutors.executors;
      },
      skip() {
        return !this.token || !this.willId;
      },
      error,
    },
  },
  data() {
    return {
      executors: [],
    };
  },
  computed: {
    getExecutorsQuery() {
      return {
        query: GET_EXECUTORS_QUERY,
        variables: {
          willId: this.willId,
        },
      };
    },
    primaryExecutors() {
      return this.executors.filter((executor) => executor.type === 'primary');
    },
    backupExecutors() {
      return this.executors.filter((executor) => executor.type === 'backup');
    },
  },
  methods: {
    isExecutor(person) {
      return !!this.executors.find(
        (executor) => executor.person.id === person.id
      );
    },
    isPrimaryExecutor(person) {
      return !!this.primaryExecutors.find(
        (executor) => executor.person.id === person.id
      );
    },
    isBackupExecutor(person) {
      return !!this.backupExecutors.find(
        (executor) => executor.person.id === person.id
      );
    },
    async addExecutor(person, type) {
      await this.$apollo
        .mutate({
          mutation: ADD_EXECUTOR_MUTATION,
          variables: {
            willId: this.willId,
            person: person.id,
            type,
          },
          update: (store, { data: { addExecutor } }) => {
            const data = store.readQuery(this.getExecutorsQuery);
            data.getExecutors.executors.push(addExecutor.executor);

            store.writeQuery({
              ...this.getExecutorsQuery,
              data,
            });
          },
        })
        .catch(error);
    },
    async removeExecutor(person) {
      const executor = this.executors.find(
        (executor) => executor.person.id === person.id
      );

      if (executor) {
        await this.$apollo
          .mutate({
            mutation: REMOVE_EXECUTOR_MUTATION,
            variables: {
              id: executor.id,
              willId: this.willId,
            },
            update: (store) => {
              const data = store.readQuery(this.getExecutorsQuery);
              const index = data.getExecutors.executors.findIndex(
                (m) => m.id === executor.id
              );

              if (index !== -1) {
                data.getExecutors.executors.splice(index, 1);

                store.writeQuery({
                  ...this.getExecutorsQuery,
                  data,
                });
              }
            },
          })
          .catch(error);
      }
    },
    async refetchExecutors() {
      await this.$apollo.queries.executors.refetch();
    },
  },
};
