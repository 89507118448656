import { mapActions } from 'vuex';
import { referralCodesToCharities } from '@/modules/referralCodes';
import { referralCharities } from '@/modules/charityData';
import { metaArrayToObject, objectToMetaArray } from '~/utilities';
import { isSameCharity } from '~/utilities/charity';

import ADD_CHARITY_MUTATION from '~/graphql/mutations/AddCharity';
import GET_CHARITIES_QUERY from '~/graphql/queries/GetCharities';
import REMOVE_CHARITY_MUTATION from '~/graphql/mutations/RemoveCharity';
import UPDATE_CHARITY_MUTATION from '~/graphql/mutations/UpdateCharity';

import { error } from '~/mixins/apollo';

export default {
  apollo: {
    charities: {
      query: GET_CHARITIES_QUERY,
      variables() {
        return {
          willId: this.willId,
        };
      },
      update: (data) => data.getCharities && data.getCharities.charities,
      skip() {
        return !this.token || !this.willId;
      },
      error,
    },
  },
  data() {
    return {
      ADD_CHARITY_MUTATION,
      REMOVE_CHARITY_MUTATION,
      UPDATE_CHARITY_MUTATION,
      charities: [],
    };
  },
  computed: {
    getCharitiesQuery() {
      return {
        query: GET_CHARITIES_QUERY,
        variables: {
          willId: this.willId,
        },
      };
    },
    hasValidReferralCharity() {
      return (
        !!this.willMeta.referral_charity &&
        !!referralCodesToCharities[this.willMeta.referral_charity]
      );
    },
    referralCharityHasRegionalCharity() {
      return referralCodesToCharities[this.willMeta.referral_charity].some(
        (charityKey) =>
          referralCharities[charityKey]?.regions &&
          referralCharities[charityKey].regions[
            this.willMeta.address_state.toLowerCase()
          ]
      );
    },
  },
  methods: {
    ...mapActions('charity', ['addCharity', 'editCharity']),
    isCharity(id) {
      return !!this.charities.filter((charity) => charity.id === id).length;
    },
    async getMatchingCharityMeta(charity) {
      const { data } = await this.$apollo.query(this.getCharitiesQuery);
      return data.getCharities.charities.find((c) =>
        isSameCharity(metaArrayToObject(c.meta), charity)
      );
    },
    async removeCharity(charity) {
      await this.$apollo
        .mutate({
          mutation: this.REMOVE_CHARITY_MUTATION,
          variables: {
            id: charity.id,
            willId: this.willId,
          },
          update: (store) => {
            const data = store.readQuery(this.getCharitiesQuery);
            const index = data.getCharities.charities.findIndex(
              (m) => m.id === charity.id
            );

            if (index !== -1) {
              data.getCharities.charities.splice(index, 1);

              store.writeQuery({
                ...this.getCharitiesQuery,
                data,
              });
            }
          },
        })
        .catch(error);
    },
    async addPartnerCharity(charity) {
      let exists = false;
      const charityMeta = {
        name: charity.name,
        address: charity.address,
        abn: charity.abn,
        logo: charity.logo,
        referral:
          this.referral_charity ||
          (this.willMeta && this.willMeta.referral_charity),
        suggestionLocation: charity.suggestionLocation,
        suggestionSlot: charity.suggestionSlot,
        searchLocation: charity.searchLocation,
        searchFeature: charity.searchFeature,
      };

      try {
        const { data } = await this.$apollo.query(this.getCharitiesQuery);
        exists = data.getCharities.charities.find((c) =>
          isSameCharity(metaArrayToObject(c.meta), charity)
        );
      } catch (err) {
        console.error(err.message);
      }

      if (!exists) {
        try {
          await this.$apollo.mutate({
            mutation: ADD_CHARITY_MUTATION,
            variables: {
              willId: this.willId,
              meta: objectToMetaArray(charityMeta),
            },
            update: (store, { data: { addCharity } }) => {
              if (addCharity) {
                const data = store.readQuery(this.getCharitiesQuery);
                data.getCharities.charities.push(addCharity.charity);

                store.writeQuery({
                  ...this.getCharitiesQuery,
                  data,
                });
              }
            },
          });

          this.$nuxt.$emit('sendTrackingAttributes', {
            charity: this.referral_charity,
          });
        } catch (err) {
          console.error(err.message);
        }
      }
    },
    async addPartnerCharities(charityKeys) {
      await Promise.all(
        charityKeys.map((charityKey) => {
          const charity = {
            ...referralCharities[charityKey],
          };
          return this.addPartnerCharity(charity);
        })
      );
    },
    async replaceNationalCharitiesWithRegionalCharities() {
      const addressState = this.willMeta.address_state.toLowerCase();
      const charityKeys = referralCodesToCharities[
        this.willMeta.referral_charity
      ].filter(
        (charityKey) =>
          referralCharities[charityKey]?.regions &&
          referralCharities[charityKey].regions[addressState]
      );
      await Promise.all(
        charityKeys.map(async (charityKey) => {
          const nationalCharity = {
            ...referralCharities[charityKey],
          };
          const regionalCharity = {
            ...nationalCharity.regions[addressState],
          };
          const matchingCharity = await this.getMatchingCharityMeta(
            nationalCharity
          );
          if (matchingCharity) await this.removeCharity(matchingCharity);
          return this.addPartnerCharity(regionalCharity);
        })
      );
    },
    async refetchCharities() {
      await this.$apollo.queries.charities.refetch();
    },
  },
};
