const ANIMALS = 'Animals';
const CHILDREN = 'Children';
const COMMUNITY = 'Community';
const EDUCATION = 'Education';
const ENVIRONMENT = 'Environment';
const EQUALITY = 'Equality';
const HEALTH = 'Health';
const SPORT = 'Sport';
const ABORIGINAL_TORRES_STRAIT_ISLAND = 'Aboriginal & Torres Strait Island';

const featuredCharities = {
  main: ['guidedogsaus', 'cpa', 'plan', 'fhf', 'cancercouncil'],
  [ANIMALS]: [],
  [CHILDREN]: [],
  [COMMUNITY]: [],
  [EDUCATION]: [],
  [ENVIRONMENT]: [],
  [EQUALITY]: [],
  [HEALTH]: [],
  [SPORT]: [],
  [ABORIGINAL_TORRES_STRAIT_ISLAND]: [],
};

const referralCharities = {
  swt: {
    name: 'Safewill Test Charity',
    address: 'Sydney',
  },
  fhf: {
    name: 'The Fred Hollows Foundation',
    address: '61 Dunning Ave, Rosebery NSW 2018',
    abn: '46 070 556 642',
    logo: require('~/assets/img/charities/logos/fred.png'),
    categories: [EQUALITY, HEALTH],
    description:
      'By leaving a gift to The Fred Hollows Foundation in your Will, you will train the next generation of eye surgeons and help transform the lives of many of the millions of people living with avoidable blindness.',
  },
  plan: {
    name: 'Plan International Australia',
    address: '18/60 City Road, Southbank, 3006',
    abn: '49 004 875 807',
    logo: require('~/assets/img/charities/logos/plan.png'),
    categories: [CHILDREN, EQUALITY, EDUCATION],
    description:
      'Self-worth. Independence. Equality. Empowerment. They’re the most valuable lessons – and today you can pass them on to children everywhere by including a gift in your Will to Plan International Australia.',
  },
  cpa: {
    name: 'Cerebral Palsy Alliance',
    address: 'PO Box 6427, Frenchs Forest, NSW, 2086',
    abn: '45 000 062 288',
    logo: require('~/assets/img/charities/logos/cpa.png'),
    categories: [HEALTH],
    description:
      'More than 37,000 Australians live with Cerebral Palsy. Leaving a gift to Cerebral Palsy Alliance will enhance these people’s lives, helping them live to their fullest potential and achieve goals they never thought possible.',
  },
  oxfam: {
    name: 'Oxfam Australia',
    address: '130-138 Leicester Street, Carlton, VIC, 3053',
    abn: '18 055 208 636',
    logo: require('~/assets/img/charities/logos/oxfam.png'),
    categories: [EQUALITY],
    description:
      'Inspire change beyond your lifetime with a gift in your Will to Oxfam Australia. You can help create a just and sustainable world without the inequalities that keep people in poverty.',
  },
  cancercouncil: {
    name: 'Cancer Council Australia',
    address: 'L 14 477 Pitt St, Haymarket NSW 2000, Australia',
    abn: '91 130 793 725',
    logo: require('~/assets/img/charities/logos/cancercouncil.png'),
    categories: [HEALTH],
    description:
      'Almost 400 Australians will be diagnosed with cancer every day. A gift to Cancer Council will help fund research, education and awareness programs, critical to achieving a cancer free future.',
    regions: {
      act: {
        name: 'The Cancer Council ACT',
        address: 'Unit 1 173 Strickland Crescent Deakin ACT 2600',
        abn: '33 113 296 601',
        logo: require('~/assets/img/charities/logos/cancercouncil-act.png'),
        categories: [HEALTH],
        description:
          'Almost 400 Australians will be diagnosed with cancer every day. A gift to Cancer Council will help fund research, education and awareness programs, critical to achieving a cancer free future.',
      },
      nsw: {
        name: 'Cancer Council NSW',
        address: '153 Dowling St, Woolloomooloo NSW 2011 Australia',
        abn: '51 116 463 846',
        logo: require('~/assets/img/charities/logos/cancercouncil-nsw.png'),
        categories: [HEALTH],
        description:
          'Almost 400 Australians will be diagnosed with cancer every day. A gift to Cancer Council will help fund research, education and awareness programs, critical to achieving a cancer free future.',
      },
      nt: {
        name: 'Cancer Council Of The Northern Territory Incorporated',
        address: '25 Vanderlin Drive Casuarina Darwin NT 0811',
        abn: '78 039 911 732',
        logo: require('~/assets/img/charities/logos/cancercouncil-nt.png'),
        categories: [HEALTH],
        description:
          'Almost 400 Australians will be diagnosed with cancer every day. A gift to Cancer Council will help fund research, education and awareness programs, critical to achieving a cancer free future.',
      },
      qld: {
        name: 'Cancer Council Queensland',
        address: '553 Gregory Tce Fortitude Valley QLD 4006',
        abn: '48 321 126 727',
        logo: require('~/assets/img/charities/logos/cancercouncil-qld.png'),
        categories: [HEALTH],
        description:
          'Almost 400 Australians will be diagnosed with cancer every day. A gift to Cancer Council will help fund research, education and awareness programs, critical to achieving a cancer free future.',
      },
      sa: {
        name: 'Anti-Cancer Foundation Of South Australia',
        address: '168 Greenhill Road, Parkside South Australia, 5063',
        abn: '29 053 873 822',
        logo: require('~/assets/img/charities/logos/cancercouncil-sa.png'),
        categories: [HEALTH],
        description:
          'Almost 400 Australians will be diagnosed with cancer every day. A gift to Cancer Council will help fund research, education and awareness programs, critical to achieving a cancer free future.',
      },
      tas: {
        name: 'Cancer Council Of Tasmania Inc',
        address: '15 Princes St Sandy Bay TAS 7005',
        abn: '27 685 250 740',
        logo: require('~/assets/img/charities/logos/cancercouncil-tas.png'),
        categories: [HEALTH],
        description:
          'Almost 400 Australians will be diagnosed with cancer every day. A gift to Cancer Council will help fund research, education and awareness programs, critical to achieving a cancer free future.',
      },
      vic: {
        name: 'Cancer Council Victoria',
        address: '615 St Kilda Road, Melbourne, VIC, 3004',
        abn: '61 426 486 715',
        logo: require('~/assets/img/charities/logos/cancercouncil-vic.png'),
        categories: [HEALTH],
        description:
          'Almost 400 Australians will be diagnosed with cancer every day. A gift to Cancer Council will help fund research, education and awareness programs, critical to achieving a cancer free future.',
      },
      wa: {
        name: 'Cancer Council Western Australia (Inc)',
        address: 'L1/420 Bagot Rd, Subiaco, WA, 6008',
        abn: '15 190 821 561',
        logo: require('~/assets/img/charities/logos/cancercouncil-wa.png'),
        categories: [HEALTH],
        description:
          'Almost 400 Australians will be diagnosed with cancer every day. A gift to Cancer Council will help fund research, education and awareness programs, critical to achieving a cancer free future.',
      },
    },
  },
  ccv: {
    name: 'Cancer Council Victoria',
    address: '615 St Kilda Road, Melbourne, VIC, 3004',
    abn: '61 426 486 715',
    logo: require('~/assets/img/charities/logos/ccv.png'),
    categories: [HEALTH],
  },
  ccnsw: {
    name: 'Cancer Council NSW',
    address: '153 Dowling St, Woolloomooloo NSW 2011 Australia',
    abn: '51 116 463 846',
    logo: require('~/assets/img/charities/logos/ccnsw.png'),
    categories: [HEALTH],
  },
  cancercouncilwa: {
    name: 'Cancer Council Western Australia (Inc)',
    address: 'L1/420 Bagot Rd, Subiaco, WA, 6008',
    abn: '15 190 821 561',
    logo: require('~/assets/img/charities/logos/ccwa.png'),
    categories: [HEALTH],
  },
  hroz: {
    name: 'Heart Research Australia',
    address: 'PO Box 543 ST LEONARDS NSW 1590',
    abn: '62 002 839 072',
    logo: require('~/assets/img/charities/logos/hroz.jpg'),
    categories: [HEALTH],
  },
  clcrf: {
    name: 'Children’s Leukaemia & Cancer Research Foundation',
    address: 'PO Box 1118, West Perth WA 6872',
    abn: '42 030 465 053',
    logo: require('~/assets/img/charities/logos/clcrf.png'),
    categories: [HEALTH, CHILDREN],
  },
  guidedogswa: {
    name: 'Guide Dogs WA',
    address: '61 Kitchener Avenue, Victoria Park, WA 6100',
    abn: 'Operated by EverAbility Group Limited ABN 11 157 291 960',
    logo: require('~/assets/img/charities/logos/guidedogswa.png'),
    categories: [ANIMALS, HEALTH],
  },
  asthma: {
    name: 'Asthma Australia',
    address: 'Level 13, Tower B, 799 Pacific Highway, Chatswood, NSW 2067',
    abn: '91 609 156 630',
    logo: require('~/assets/img/charities/logos/aa.png'),
    categories: [HEALTH],
  },
  ms: {
    name: 'Multiple Sclerosis Limited',
    address: '54 Railway Rd, Blackburn VIC 3130',
    abn: '66 004 942 287',
    logo: require('~/assets/img/charities/logos/ms.png'),
    categories: [HEALTH],
  },
  schf: {
    name: 'Sydney Children’s Hospitals Foundation',
    address: 'GPO Box 4077, Sydney NSW 2000',
    abn: '72 003 073 185',
    logo: require('~/assets/img/charities/logos/schf.png'),
    categories: [HEALTH, CHILDREN],
  },
  ifaw: {
    name: 'International Fund for Animal Welfare (Australia) Pty Ltd',
    address: '6 Belmore Street, Surry Hills NSW 2010',
    abn: '90 002 655 754',
    logo: require('~/assets/img/charities/logos/ifaw.png'),
    categories: [ANIMALS],
  },
  cbc: {
    name: 'Cure Brain Cancer Foundation',
    address: 'Level 7, 23 O’Connell Street, Sydney NSW 2000',
    abn: '22 121 906 036',
    logo: require('~/assets/img/charities/logos/cbc.png'),
    categories: [HEALTH],
  },
  charlies: {
    name: 'Charlies Foundation for Research Ltd',
    address: 'PO Box 240 Nedlands WA 6909',
    abn: '24 165 388 125',
    logo: require('~/assets/img/charities/logos/charlies.png'),
    categories: [HEALTH],
  },
  curecancer: {
    name: 'Cure Cancer Australia Foundation',
    address: 'PO Box A2434 Sydney South NSW 1235',
    abn: '13 002 838 495',
    logo: require('~/assets/img/charities/logos/curecancer.png'),
    categories: [HEALTH],
  },
  bowelcancer: {
    name:
      'Bowel Cancer and Digestive Research Institute Australia (trading as Bowel Cancer Australia)',
    address: 'Level 2 65 Walker Street North Sydney NSW 2060',
    abn: '98 095 158 633',
    logo: require('~/assets/img/charities/logos/bowel-cancer.png'),
    categories: [HEALTH],
  },
  nsf: {
    name: 'National Stroke Foundation',
    address: 'Level 7, 461 Bourke Street, Melbourne, VIC, 3000',
    abn: '42 006 173 379',
    logo: require('~/assets/img/charities/logos/nsf.png'),
    categories: [HEALTH],
  },
  deakin: {
    name: 'Deakin University',
    address: '1 Gheringhap Street, Geelong, Victoria, 3220',
    abn: '56 721 584 203',
    logo: require('~/assets/img/charities/logos/deakin.png'),
    categories: [EDUCATION, COMMUNITY],
  },
  tbbf: {
    name: 'The Bob Brown Foundation Inc',
    address: 'Level 4, 116 Bathurst St. Hobart Tasmania 7005',
    abn: '51 634 785 002',
    logo: require('~/assets/img/charities/logos/tbbf.png'),
    categories: [ENVIRONMENT],
  },
  msra: {
    name: 'Multiple Sclerosis Research Australia Limited',
    address: 'Level 19, 100 Miller Street, North Sydney NSW 2060',
    abn: '34 008 581 431',
    logo: require('~/assets/img/charities/logos/msra.png'),
    categories: [HEALTH],
  },
  stca: {
    name: 'Save The Children Australia',
    address: '33 Lincoln Square South, Carlton VIC 3053',
    abn: '99 008 610 035',
    logo: require('~/assets/img/charities/logos/stca.png'),
    categories: [EQUALITY, CHILDREN],
  },
  msf: {
    name: 'Médecins Sans Frontières Australia Ltd',
    address: 'PO Box 847, Broadway, NSW 2007 Australia',
    abn: '74 068 758 654',
    logo: require('~/assets/img/charities/logos/msfa.png'),
    categories: [HEALTH, EQUALITY],
    description:
      'Create a legacy without borders. A gift in your Will to MSF can help provide medical assistance to people affected by conflict, epidemics, disasters, or exclusion from healthcare.',
  },
  tsc: {
    name: 'The Shepherd Centre – for deaf children',
    address: '146 Burren Street, Newtown NSW 2042',
    abn: '61 000 699 927',
    logo: require('~/assets/img/charities/logos/tsc.png'),
    categories: [EQUALITY, HEALTH, CHILDREN],
  },
  mswa: {
    name: 'Multiple Sclerosis Society of WA (MSWA)',
    address: '154 Abernethy Road Belmont, WA 6104',
    abn: '75 638 080 972',
    logo: require('~/assets/img/charities/logos/mswa.png'),
    categories: [HEALTH],
  },
  diabetesvic: {
    name: 'Diabetes Victoria',
    address: '570 Elizabeth Street Melbourne VIC 3000',
    abn: '71 005 239 510',
    logo: require('~/assets/img/charities/logos/diabetesvic.png'),
    categories: [HEALTH],
  },
  aarf: {
    name: 'Australian Alzheimer’s Research Foundation Inc',
    address: '8 Verdun Street, Nedlands, Western Australia, 6009',
    abn: '34 575 647 667',
    logo: require('~/assets/img/charities/logos/aarf.png'),
    categories: [HEALTH],
  },
  tai: {
    name: 'The Australia Institute',
    address: 'Level 1 Endeavour House, 1 Franklin St, Manuka ACT 2603',
    abn: '90 061 969 284',
    logo: require('~/assets/img/charities/logos/tai.png'),
    categories: [EDUCATION],
  },
  asrc: {
    name: 'Asylum Seeker Resource Centre Incorporated',
    address: '214-218 Nicholson Street, Footscray, Victoria, Australia 3011',
    abn: '64 114 965 815',
    logo: require('~/assets/img/charities/logos/asrc.png'),
    categories: [EQUALITY],
  },
  lfa: {
    name: 'Lung Foundation Australia',
    address: 'Level 2, 11 Finchley Street, Milton QLD 4064',
    abn: '36 051 131 901',
    logo: require('~/assets/img/charities/logos/lfa.png'),
    categories: [HEALTH],
  },
  camp: {
    name: 'Camp Quality Limited',
    address: 'Level 1, 182 Blues Point Road McMahons Point NSW 2060',
    abn: '87 052 097 720',
    logo: require('~/assets/img/charities/logos/camp.png'),
    categories: [HEALTH, COMMUNITY, CHILDREN],
  },
  gsjmf: {
    name: 'General Sir John Monash Foundation',
    address: 'Bennelong House, 9 Queen Street Melbourne Victoria 3000',
    abn: '78 099 065 184',
    logo: require('~/assets/img/charities/logos/gsjmf.png'),
    categories: [EDUCATION],
  },
  kha: {
    name:
      'The Australian Kidney Foundation (trading as Kidney Health Australia)',
    address: 'Bennelong House, 9 Queen Street Melbourne Victoria 3000',
    abn: '37 008 464 426',
    logo: require('~/assets/img/charities/logos/kha.png'),
    categories: [HEALTH],
    background: require('~/assets/img/charities/backgrounds/kha.jpg'),
  },
  ywca: {
    name: 'YWCA Australia',
    address: 'Level 1/210 Kings Way, South Melbourne VIC 3205',
    abn: '74 111 663 873',
    logo: require('~/assets/img/charities/logos/ywca.png'),
    categories: [EQUALITY],
  },
  wildlifevic: {
    name: 'Wildlife Victoria Inc',
    address: 'Wildlife Victoria, 1 St Heliers St, Abbotsford VIC 3067',
    abn: '27 753 478 012',
    logo: require('~/assets/img/charities/logos/wildlifevic.png'),
    categories: [ANIMALS],
  },
  slsf: {
    name: 'The Surf Life Saving Foundation',
    address: '190 Montpelier Road, Bowen Hills, QLD 4006',
    abn: '47 945 812 553',
    logo: require('~/assets/img/charities/logos/slsf.png'),
    categories: [COMMUNITY],
  },
  youngcare: {
    name: 'Youngcare Ltd',
    address: '75 St Pauls Tce, SPRING HILL, QLD, 4000',
    abn: '17 113 923 032',
    logo: require('~/assets/img/charities/logos/youngcare.png'),
    categories: [EQUALITY, CHILDREN],
  },
  swans: {
    name: 'Sydney Swans Foundation Limited',
    address: 'PO Box 173, Paddington NSW 2021',
    abn: '48 063 349 708',
    logo: require('~/assets/img/charities/logos/swans.png'),
    categories: [COMMUNITY, SPORT],
  },
  care: {
    name: 'CARE Australia',
    address: 'Ground Floor, 243 Northbourne Ave, Lyneham, ACT 2602',
    abn: '46 003 380 890',
    logo: require('~/assets/img/charities/logos/care.png'),
    categories: [EQUALITY],
  },
  canteen: {
    name:
      'Canteen – The Australian Organisation for Young People Living with Cancer',
    address: '75 King Street, Newtown, NSW 2042',
    abn: '77 052 040 516',
    logo: require('~/assets/img/charities/logos/canteen.png'),
    categories: [HEALTH],
  },
  cws: {
    name: 'Cancer Wellness Support Incorporated',
    address: '104 Railway Pde, Leura, NSW, 2780',
    abn: '67 202 763 705',
    logo: require('~/assets/img/charities/logos/cws.png'),
    categories: [HEALTH],
  },
  gda: {
    name: 'Guide Dogs NSW/ACT',
    address: '2-4 Thomas Street, Chatswood NSW 2067',
    abn: '52 000 399 744',
    logo: require('~/assets/img/charities/logos/gda.png'),
    categories: [ANIMALS, HEALTH],
  },
  lifeline: {
    name: 'Lifeline Australia',
    address: 'Level 12, 70 Phillip St, CBD, Sydney 2000',
    abn: '84 081 031 263',
    logo: require('~/assets/img/charities/logos/lifeline.png'),
    categories: [COMMUNITY, HEALTH],
  },
  svdpwa: {
    name: 'St Vincent de Paul Society WA',
    address: '9 Brewer Road Canning Vale WA 6155',
    abn: '18 332 550 061',
    logo: require('~/assets/img/charities/logos/svdpwa.jpg'),
    categories: [EQUALITY, COMMUNITY],
  },
  nationaltrust: {
    name: 'National Trust of Australia (NSW)',
    address: 'PO Box 518, Sydney NSW 2001',
    abn: '82 491 958 802',
    logo: require('~/assets/img/charities/logos/nationaltrust.png'),
    categories: [ENVIRONMENT, COMMUNITY],
  },
  rspca: {
    name: 'RSPCA Australia',
    address: 'Unit 4/5, 6 Napier Close, Deakin ACT 2600, Australia',
    abn: '99 668 654 249',
    logo: require('~/assets/img/charities/logos/rspca.png'),
    categories: [ANIMALS],
    background: require('~/assets/img/charities/backgrounds/rspca.jpg'),
    regions: {
      act: {
        name: 'RSPCA Australian Capital Territory',
        address: '12 Kirkpatrick Street, Weston ACT 2611',
        abn: '35 730 738 037',
        logo: require('~/assets/img/charities/logos/rspca-act.png'),
        categories: [ANIMALS],
      },
      nsw: {
        name: 'RSPCA New South Wales',
        address: '201 Rookwood Road, Yagoona NSW 2199',
        abn: '87 000 001 641',
        logo: require('~/assets/img/charities/logos/rspca-nsw.png'),
        categories: [ANIMALS],
      },
      nt: {
        name: 'RSPCA Darwin',
        address: 'PO Box 40034, Casuarina NT 0811 Australia',
        abn: '42 603 546 873',
        logo: require('~/assets/img/charities/logos/rspca-nt.png'),
        categories: [ANIMALS],
      },
      qld: {
        name: 'RSPCA Queensland',
        address: 'Locked Bag 3000, Archerfield BH QLD 4108 Australia',
        abn: '74 851 544 037',
        logo: require('~/assets/img/charities/logos/rspca-qld.png'),
        categories: [ANIMALS],
      },
      sa: {
        name: 'RSPCA South Australia',
        address: '16 Nelson St, Stepney SA 5069',
        abn: '60 740 135 753',
        logo: require('~/assets/img/charities/logos/rspca-sa.png'),
        categories: [ANIMALS],
      },
      tas: {
        name: 'RSPCA Tasmania',
        address: 'Shop 3, 207A Invermay Road INVERMAY TAS 7248',
        abn: '71 723 781 546',
        logo: require('~/assets/img/charities/logos/rspca-tas.png'),
        categories: [ANIMALS],
      },
      vic: {
        name: 'RSPCA Victoria',
        address: '3 Burwood Hwy, Burwood East VIC 3151',
        abn: '56 749 449 191',
        logo: require('~/assets/img/charities/logos/rspca-vic.png'),
        categories: [ANIMALS],
      },
      wa: {
        name: 'RSPCA Western Australia',
        address: '108 Malaga Drive, Malaga WA 6090',
        abn: '48 626 609 587',
        logo: require('~/assets/img/charities/logos/rspca-wa.png'),
        categories: [ANIMALS],
      },
    },
  },
  ozharvest: {
    name: 'Oz Harvest Limited',
    address: 'G3/G4, 46-62 Maddox St, Alexandria, NSW 2015',
    abn: '33 107 782 196',
    logo: require('~/assets/img/charities/logos/ozharvest.png'),
    categories: [EQUALITY, COMMUNITY],
  },
  baker: {
    name: 'Baker Heart and Diabetes Institute',
    address: '75 Commercial Road, Melbourne, Victoria 3004, Australia',
    abn: '98 131 762 948',
    logo: require('~/assets/img/charities/logos/baker.png'),
    categories: [HEALTH],
  },
  svdpvic: {
    name: 'St Vincent de Paul Society Victoria',
    address: '43 Prospect Street, Box Hill VIC 3128',
    abn: '28 911 702 061',
    logo: require('~/assets/img/charities/logos/svdpvic.png'),
    categories: [EQUALITY, COMMUNITY],
  },
  animalsaustralia: {
    name: 'Animals Australia Federation Limited',
    address: '37 O’Connell St North Melbourne VIC 3051',
    abn: '65 016 845 767',
    logo: require('~/assets/img/charities/logos/animalsaustralia.png'),
    categories: [ANIMALS],
  },
  ethiopiaid: {
    name: 'Ethiopiaid Australia Foundation',
    address: 'Level 3, 484 St Kilda Road Melbourne VIC 3004',
    abn: '78 821 615 548',
    logo: require('~/assets/img/charities/logos/ethiopiaid.png'),
    categories: [EQUALITY],
  },
  anzgog: {
    name: 'Australia New Zealand Gynaecological Oncology Group',
    address: '119-143 Missenden Rd, Camperdown NSW 2050',
    abn: '69 138 649 028',
    logo: require('~/assets/img/charities/logos/anzgog.png'),
    categories: [HEALTH],
  },
  ccia: {
    name: 'Children’s Cancer Institute Australia',
    address: 'Lowy Cancer Research Centre, C25/9 High St, Kensington NSW 2750',
    abn: '41 072 279 559',
    logo: require('~/assets/img/charities/logos/ccia.png'),
    categories: [HEALTH, CHILDREN],
  },
  cmri: {
    name: 'Children’s Medical Research Institute',
    address: '14 Hawkesbury Rd, Westmead NSW 2145',
    abn: '47 002 684 737',
    logo: require('~/assets/img/charities/logos/cmri.png'),
    categories: [HEALTH, CHILDREN],
  },
  aurora: {
    name: 'Aurora Education Foundation Limited',
    address: '100 Botany Rd, Alexandria, NSW 2015',
    abn: '28 158 391 363',
    logo: require('~/assets/img/charities/logos/aurora.png'),
    categories: [EDUCATION, ABORIGINAL_TORRES_STRAIT_ISLAND],
  },
  redr: {
    name: 'Redr Australia',
    address: '55-61 Barry St, Carlton, VIC 3053',
    abn: '89 068 902 821',
    logo: require('~/assets/img/charities/logos/redr.png'),
    categories: [EQUALITY],
  },
  baptistmissionaustralia: {
    name: 'Baptist Mission Australia',
    address: '597 Burwood Rd, Hawthorn, VIC 3122',
    abn: '74 130 443 130',
    logo: require('~/assets/img/charities/logos/baptistmissionaustralia.png'),
    categories: [EDUCATION],
  },
  nextsense: {
    name: 'Royal Institute for Deaf and Blind Children',
    address: '361-365 North Rocks Road, North Rocks NSW 2151',
    abn: '53 443 272 865',
    logo: require('~/assets/img/charities/logos/nextsense.png'),
    categories: [HEALTH, CHILDREN],
  },
  lei: {
    name: 'Lions Eye Institute Limited',
    address: '2 Verdun St, Nedlands, Western Australia 6009, Australia',
    abn: '48 106 521 439',
    logo: require('~/assets/img/charities/logos/lei.png'),
    categories: [HEALTH],
  },
  leprosymission: {
    name: 'The Leprosy Mission Australia',
    address: '37-39 Ellingworth Pde, Box Hill, Victoria 3128',
    abn: '52 354 004 543',
    logo: require('~/assets/img/charities/logos/leprosymission.png'),
    categories: [HEALTH],
  },
  ina: {
    name: 'International Needs Australia',
    address: '196-202 Rooks Rd, Vermont, Victoria 3133, Australia',
    abn: '84 006 053 229',
    logo: require('~/assets/img/charities/logos/ina.png'),
    categories: [EQUALITY],
    background: require('~/assets/img/charities/backgrounds/ina.jpg'),
  },
  unitingworld: {
    name: 'UnitingWorld',
    address: 'Level 3, 262 Pitt St, SYDNEY, NSW, 2000',
    abn: '47 817 183 926',
    logo: require('~/assets/img/charities/logos/unitingworld.png'),
    categories: [EQUALITY],
  },
  schoolforlife: {
    name: 'School For Life Foundation Australia Limited',
    address:
      'Suite 11, Level 2, 20 Clarke Street, Crows Nest NSW 2065 Australia',
    abn: '44 134 595 681',
    logo: require('~/assets/img/charities/logos/schoolforlife.png'),
    categories: [EQUALITY, EDUCATION, CHILDREN],
    background: require('~/assets/img/charities/backgrounds/schoolforlife.jpg'),
  },
  greenpeace: {
    name: 'Greenpeace Australia Pacific Limited',
    address: '2C Hayes Road, Rosebery, New South Wales 2018, Australia',
    abn: '61 002 643 852',
    logo: require('~/assets/img/charities/logos/greenpeace.png'),
    categories: [ENVIRONMENT, ANIMALS],
  },
  aspect: {
    name: 'Autism Spectrum Australia (Aspect)',
    address: 'L2 14 Aquatic Dr, Frenchs Forest, New South Wales 2086',
    abn: '12 000 637 267',
    logo: require('~/assets/img/charities/logos/aspect.png'),
    categories: [HEALTH],
  },
  rfw: {
    name: 'Royal Far West',
    address: '14-18 Wentworth Street, Manly NSW 2095',
    abn: '37 821 970 819',
    logo: require('~/assets/img/charities/logos/rfw.png'),
    categories: [HEALTH, EDUCATION, CHILDREN],
  },
  acon: {
    name: 'Acon Health Limited',
    address:
      '414-418 Elizabeth St, Surry Hills, New South Wales 2010, Australia',
    abn: '38 136 883 915',
    logo: require('~/assets/img/charities/logos/acon.png'),
    categories: [HEALTH, COMMUNITY],
  },
  nswliberal: {
    name: 'Liberal Party of Australia – NSW Division',
    address: 'Level 12,100 William Street, East Sydney, NSW, 2011',
    abn: '68 324 857 904',
    logo: require('~/assets/img/charities/logos/nswliberal.png'),
    categories: [COMMUNITY],
    background: require('~/assets/img/charities/backgrounds/nswliberal.jpg'),
  },
  charitybounce: {
    name: 'Charity Bounce Limited',
    address: 'Level 21 133-145 Castlereagh St, Sydney, NSW, 2000',
    abn: '58 165 965 435',
    logo: require('~/assets/img/charities/logos/charitybounce.png'),
    categories: [EQUALITY, COMMUNITY, CHILDREN],
  },
  awlnsw: {
    name: 'Animal Welfare League NSW',
    address:
      '1605-1667 Elizabeth Dr, Kemps Creek, New South Wales 2178, Australia',
    abn: '88 000 533 086',
    logo: require('~/assets/img/charities/logos/awlnsw.png'),
    categories: [ANIMALS],
  },
  svdpnsw: {
    name: 'St Vincent de Paul Society NSW',
    address: 'PO BOX 5 Petersham, NSW, 2049',
    abn: '91 161 127 340',
    logo: require('~/assets/img/charities/logos/svdpnsw.png'),
    categories: [EQUALITY, COMMUNITY],
  },
  wilderness: {
    name: 'The Wilderness Society Ltd.',
    address: '132 Davey Street, Hobart Tasmania 7000',
    abn: '18 611 229 086',
    logo: require('~/assets/img/charities/logos/wilderness.png'),
    categories: [ENVIRONMENT],
  },
  royalwomen: {
    name: 'The Royal Hospital for Women Foundation',
    address: 'Level 1 Barker Street, Randwick, NSW, 2031',
    abn: '79 276 416 465',
    logo: require('~/assets/img/charities/logos/royalwomen.png'),
    categories: [HEALTH],
  },
  caritas: {
    name: 'Caritas Australia Limited',
    address: '24-32 O’Riordan st Alexandria NSW 2015',
    abn: '90 970 605 069',
    logo: require('~/assets/img/charities/logos/caritas.png'),
    categories: [EQUALITY],
  },
  hudson: {
    name: 'Hudson Institute of Medical Research',
    address: '27-31 Wright Street, Clayton, VIC 3168',
    abn: '48 132 025 024',
    logo: require('~/assets/img/charities/logos/hudson.png'),
    categories: [HEALTH],
  },
  catprotection: {
    name: 'The Cat Protection Society of NSW Limited',
    address: '103 Enmore Road, Newtown, NSW 2042',
    abn: '81 610 951 615',
    logo: require('~/assets/img/charities/logos/catprotection.png'),
    categories: [ANIMALS],
  },
  cathaven: {
    name: 'Cat Welfare Society Inc',
    address: '23 Lemnos Street, Shenton Park, Perth, WA 6008',
    abn: '35 968 264 862',
    logo: require('~/assets/img/charities/logos/cathaven.png'),
    categories: [ANIMALS],
  },
  rsb: {
    name: 'Royal Society for the Blind of South Australia Inc.',
    address: '11 Blacks Road, Gilles Plains, SA 5086',
    abn: '37 680 837 839',
    logo: require('~/assets/img/charities/logos/rsb.png'),
    categories: [HEALTH],
  },
  ncf: {
    name: 'National Cancer Foundation Limited',
    address: '119 Greenhill Rd, Unley, SA 5061',
    abn: '47 164 442 942',
    logo: require('~/assets/img/charities/logos/ncf.png'),
    categories: [HEALTH],
  },
  rotaryfoundation: {
    name: 'The Rotary Foundation of Rotary International',
    address: 'One Rotary Center 1560 Sherman Avenue, Evanston, IL 60201 USA',
    abn: '36-3245072',
    logo: require('~/assets/img/charities/logos/rotaryfoundation.png'),
    categories: [CHILDREN, COMMUNITY, EDUCATION, ENVIRONMENT, EQUALITY, HEALTH],
  },
  slswa: {
    name: 'Surf Life Saving Western Australia',
    address: '7 Delawney Street, Balcatta, WA 6021',
    abn: '38 926 034 500',
    logo: require('~/assets/img/charities/logos/slswa.png'),
    categories: [COMMUNITY],
  },
  centenaryinstitute: {
    name: 'Centenary Institute Medical Research Foundation',
    address: 'Building 93, Missenden Rd, Camperdown, NSW 2050',
    abn: '85 778 244 012',
    logo: require('~/assets/img/charities/logos/centenaryinstitute.png'),
    categories: [HEALTH],
  },
  rawcs: {
    name: 'Rotary Australia World Community Service Ltd',
    address: '25B/1 Maitland Place, Norwest, NSW 2153',
    abn: '37 739 341 003',
    logo: require('~/assets/img/charities/logos/rawcs.png'),
    categories: [COMMUNITY],
  },
  wwf: {
    name: 'World Wide Fund for Nature Australia (WWF)',
    address: 'Level 1, 1 Smail Street, Ultimo, NSW 2007',
    abn: '57 001 594 074',
    logo: require('~/assets/img/charities/logos/wwf.png'),
    categories: [ANIMALS, ENVIRONMENT],
  },
  mwb: {
    name: 'Mission Without Borders (Australia) Limited',
    address: '49/8 Avenue of the Americas, Newington, NSW 2127',
    abn: '38 000 827 798',
    logo: require('~/assets/img/charities/logos/mwb.png'),
    categories: [COMMUNITY],
  },
  petwelfare: {
    name: 'Australian Pet Welfare Foundation',
    address: 'Rear Office, 2059 Moggill Road, Kenmore, Queensland 4069',
    abn: '14 156 658 721',
    logo: require('~/assets/img/charities/logos/petwelfare.png'),
    categories: [ANIMALS],
  },
  redcross: {
    name: 'Australian Red Cross Society',
    address: 'PO Box 2957, Melbourne, VIC 8060',
    abn: '50 169 561 394',
    logo: require('~/assets/img/charities/logos/redcross.png'),
    categories: [COMMUNITY],
    description:
      "Hope, courage, connection, humanity - different things are important to different people. By including Australian Red Cross in your Will, you'll be acting for humanity and powering ongoing support for people facing disasters and crisis",
  },
  amnesty: {
    name: 'Amnesty International Australia',
    address: 'Level 1, 79 Myrtle Street, Chippendale Sydney, NSW, 2008',
    abn: '64 002 806 233',
    logo: require('~/assets/img/charities/logos/amnesty.png'),
    categories: [COMMUNITY, EQUALITY],
  },
  autismqld: {
    name: 'Autism Queensland Limited',
    address: '437 Hellawell Road, SUNNYBANK HILLS, Queensland, 4109',
    abn: '79 253 351 418',
    logo: require('~/assets/img/charities/logos/autismqld.png'),
    categories: [CHILDREN, COMMUNITY, EDUCATION],
  },
  habitat: {
    name: 'Habitat for Humanity Australia',
    address: 'Level 9, Suite 2, 80 Mount Street, North Sydney, NSW, 2060',
    abn: '29 131 976 004',
    logo: require('~/assets/img/charities/logos/habitat.png'),
    categories: [CHILDREN, COMMUNITY, EDUCATION, EQUALITY, HEALTH],
  },
  fullstop: {
    name: 'Full Stop Australia Ltd',
    address: 'PO Box 555, Drummoyne , NSW, 2047',
    abn: '58 023 656 939',
    logo: require('~/assets/img/charities/logos/fullstop.png'),
    categories: [CHILDREN, COMMUNITY, EQUALITY, HEALTH],
  },
  cantoo: {
    name: 'Can Too Foundation Limited',
    address: 'Suite 304, 100 William Street, Woolloomooloo, NSW, 2011',
    abn: '53 169 310 696',
    logo: require('~/assets/img/charities/logos/cantoo.png'),
    categories: [EDUCATION, HEALTH, SPORT],
  },
  infantshome: {
    name: 'Infants’ Home Ashfield',
    address: '17 Henry Street, Ashfield, NSW, 2131',
    abn: '71 174 918 661',
    logo: require('~/assets/img/charities/logos/infantshome.png'),
    categories: [EDUCATION, COMMUNITY, EQUALITY, CHILDREN],
  },
  epc: {
    name: ' Eastern Palliative Care Association Incorporated',
    address: 'Level 1, Building 2, 630 Mitcham Road, Mitcham, Victoria 3123',
    abn: '62 982 157 121',
    logo: require('~/assets/img/charities/logos/epc.png'),
    categories: [COMMUNITY],
  },
  cbrt: {
    name: 'Cancer and Bowel Research Trust',
    address: '119 Greenhill Rd, Unley, SA 5061',
    abn: '44 182 208 052',
    logo: require('~/assets/img/charities/logos/cbrt.png'),
    categories: [HEALTH],
  },
  vsk: {
    name: 'Very Special Kids',
    address: '321 Glenferrie Rd, Malvern, VIC, 3144',
    abn: '86 109 832 091',
    logo: require('~/assets/img/charities/logos/vsk.png'),
    categories: [CHILDREN, HEALTH],
  },
  cpl: {
    name: 'CPL – Choice, Passion, Life',
    address: '340 Adelaide Street, Brisbane City, QLD, 4000',
    abn: '27 009 942 269',
    logo: require('~/assets/img/charities/logos/cpl.png'),
    categories: [HEALTH, COMMUNITY, CHILDREN, EQUALITY],
  },
  ucq: {
    name: 'UnitingCare Queensland',
    address: 'Level 5, 192 Ann St, Brisbane, QLD, 4000, Australia',
    abn: '45 414 098 573',
    logo: require('~/assets/img/charities/logos/ucq.png'),
    categories: [HEALTH, COMMUNITY],
  },
  lifelinequeensland: {
    name: 'UnitingCare Community (Lifeline Queensland)',
    address: 'Level 5, 192 Ann St, Brisbane, QLD, 4000, Australia',
    abn: '28 728 322 186',
    logo: require('~/assets/img/charities/logos/lifelinequeensland.png'),
    categories: [HEALTH, COMMUNITY],
  },
  bluecare: {
    name: 'Blue Care',
    address: 'Level 5, 192 Ann St, Brisbane, QLD, 4000, Australia',
    abn: '96 010 643 909',
    logo: require('~/assets/img/charities/logos/bluecare.png'),
    categories: [HEALTH, COMMUNITY],
  },
  wesley: {
    name: 'UnitingCare Health (The Wesley Hospital)',
    address: 'Level 5, 192 Ann St, Brisbane, QLD, 4000, Australia',
    abn: '87 842 457 440',
    logo: require('~/assets/img/charities/logos/wesley.png'),
    categories: [HEALTH, COMMUNITY],
  },
  standrews: {
    name: 'UnitingCare Health (St Andrews War Memorial Hospital)',
    address: 'Level 5, 192 Ann St, Brisbane, QLD, 4000, Australia',
    abn: '87 842 457 440',
    logo: require('~/assets/img/charities/logos/standrews.png'),
    categories: [HEALTH, COMMUNITY],
  },
  buderim: {
    name: 'UnitingCare Health (Buderim Private Hospital)',
    address: 'Level 5, 192 Ann St, Brisbane, QLD, 4000, Australia',
    abn: '87 842 457 440',
    logo: require('~/assets/img/charities/logos/buderim.png'),
    categories: [HEALTH, COMMUNITY],
  },
  ststephens: {
    name: 'UnitingCare Health (St Stephens Hospital)',
    address: 'Level 5, 192 Ann St, Brisbane, QLD, 4000, Australia',
    abn: '87 842 457 440',
    logo: require('~/assets/img/charities/logos/ststephens.png'),
    categories: [HEALTH, COMMUNITY],
  },
  arrcs: {
    name: 'Australian Regional and Remote Community Services Limited',
    address: 'Level 5, 192 Ann St, Brisbane, QLD, 4000, Australia',
    abn: '88 167 926 132',
    logo: require('~/assets/img/charities/logos/arrcs.png'),
    categories: [HEALTH, COMMUNITY],
  },
  wellways: {
    name: 'Wellways Australia Ltd',
    address: '276 Heidelberg Road, Fairfield, VIC, 3078',
    abn: '93 093 357 165',
    logo: require('~/assets/img/charities/logos/wellways.png'),
    categories: [HEALTH, COMMUNITY],
  },
  mwf: {
    name: 'Making Waves Foundation Inc',
    address: 'L1, 1C New Beach Rd, Darling Point, NSW, 2027',
    abn: '82 714 459 575',
    logo: require('~/assets/img/charities/logos/mwf.png'),
    categories: [CHILDREN, EQUALITY, SPORT],
  },
  missionaustralia: {
    name: 'Mission Australia',
    address: 'Level 7, 580 George St, Sydney, NSW, 2000',
    abn: '15 000 002 522',
    logo: require('~/assets/img/charities/logos/missionaustralia.png'),
    categories: [COMMUNITY],
  },
  wizovic: {
    name: 'WIZO Victoria Inc',
    address: '306 Hawthorn Road, Caulfield, VIC 3162',
    abn: '50 756 635 036',
    logo: require('~/assets/img/charities/logos/wizovic.png'),
    categories: [CHILDREN, COMMUNITY, EDUCATION, EQUALITY],
  },
  childhoodcancer: {
    name: 'Childhood Cancer Association Inc',
    address: '55 King William Road, North Adelaide, South Australia, 5006',
    abn: '49 917 925 266',
    logo: require('~/assets/img/charities/logos/childhoodcancer.png'),
    categories: [CHILDREN, EDUCATION, HEALTH],
  },
  materfoundation: {
    name: 'Mater Foundation Limited as Trustee for Mater Foundation',
    address: '14 Stratton Street, Newstead, QLD, 4006, Australia',
    abn: '96 723 184 640',
    logo: require('~/assets/img/charities/logos/materfoundation.png'),
    categories: [
      CHILDREN,
      COMMUNITY,
      EDUCATION,
      HEALTH,
      ABORIGINAL_TORRES_STRAIT_ISLAND,
    ],
  },
  varietywa: {
    name: 'Variety WA Incorporated',
    address: '102 Burswood Rd, Burswood, WA, 6100',
    abn: '14 020 124 537',
    logo: require('~/assets/img/charities/logos/varietywa.png'),
    categories: [CHILDREN],
  },
  orangesky: {
    name: 'Orange Sky Australia Limited',
    address: '17 Dover Street, Albion, QLD, 4010',
    abn: '85 890 622 990',
    logo: require('~/assets/img/charities/logos/orangesky.png'),
    categories: [COMMUNITY],
  },
  salvationarmy: {
    name: 'The Salvation Army',
    address: '95-99 Railway Road, Blackburn Victoria 3130',
    logo: require('~/assets/img/charities/logos/salvationarmy.png'),
    categories: [CHILDREN, COMMUNITY],
  },
  guidedogsaus: {
    name: 'Royal Guide Dogs Australia',
    address: '7-9 Albany Street, St Leonards NSW 2065',
    abn: '99 008 427 423',
    logo: require('~/assets/img/charities/logos/guidedogsaus.png'),
    categories: [ANIMALS, HEALTH],
    description:
      'A gift in your Will to Guide Dogs is a life-changing legacy, providing independence and freedom for Australians with low vision or blindness for many years to come.',
    regions: {
      act: {
        name: 'Guide Dogs NSW/ACT',
        address: 'L 14 477 Pitt St, Haymarket NSW 2000, Australia',
        abn: '91 130 793 725',
        logo: require('~/assets/img/charities/logos/guidedogsaus.png'),
        categories: [ANIMALS, HEALTH],
        description:
          'A gift in your Will to Guide Dogs is a life-changing legacy, providing independence and freedom for Australians with low vision or blindness for many years to come.',
      },
      nsw: {
        name: 'Guide Dogs NSW/ACT',
        address: 'L 14 477 Pitt St, Haymarket NSW 2000, Australia',
        abn: '91 130 793 725',
        logo: require('~/assets/img/charities/logos/guidedogsaus.png'),
        categories: [ANIMALS, HEALTH],
        description:
          'A gift in your Will to Guide Dogs is a life-changing legacy, providing independence and freedom for Australians with low vision or blindness for many years to come.',
      },
      nt: {
        name: 'Guide Dogs SA/NT',
        address: '251 Morphett St Adelaide SA 5000',
        abn: '91 183 168 093',
        logo: require('~/assets/img/charities/logos/guidedogsaus.png'),
        categories: [ANIMALS, HEALTH],
        description:
          'A gift in your Will to Guide Dogs is a life-changing legacy, providing independence and freedom for Australians with low vision or blindness for many years to come.',
      },
      qld: {
        name: 'Guide Dogs QLD',
        address: '1978 Gympie Road Bald Hills QLD 4036',
        abn: '89 009 739 664',
        logo: require('~/assets/img/charities/logos/guidedogsaus.png'),
        categories: [ANIMALS, HEALTH],
        description:
          'A gift in your Will to Guide Dogs is a life-changing legacy, providing independence and freedom for Australians with low vision or blindness for many years to come.',
      },
      sa: {
        name: 'Guide Dogs SA/NT',
        address: '251 Morphett St Adelaide SA 5000',
        abn: '91 183 168 093',
        logo: require('~/assets/img/charities/logos/guidedogsaus.png'),
        categories: [ANIMALS, HEALTH],
        description:
          'A gift in your Will to Guide Dogs is a life-changing legacy, providing independence and freedom for Australians with low vision or blindness for many years to come.',
      },
      tas: {
        name: 'Royal Guide Dogs Australia',
        address: '7-9 Albany Street, St Leonards NSW 2065',
        abn: '99 008 427 423',
        logo: require('~/assets/img/charities/logos/guidedogsaus.png'),
        categories: [ANIMALS, HEALTH],
        description:
          'A gift in your Will to Guide Dogs is a life-changing legacy, providing independence and freedom for Australians with low vision or blindness for many years to come.',
      },
      vic: {
        name: 'Guide Dogs VIC',
        address: '2-6 Chandler Highway Kew VIC 3101',
        abn: '68 004 621 461',
        logo: require('~/assets/img/charities/logos/guidedogsaus.png'),
        categories: [ANIMALS, HEALTH],
        description:
          'A gift in your Will to Guide Dogs is a life-changing legacy, providing independence and freedom for Australians with low vision or blindness for many years to come.',
      },
      wa: {
        name: 'Royal Guide Dogs Australia',
        address: '7-9 Albany Street, St Leonards NSW 2065',
        abn: '99 008 427 423',
        logo: require('~/assets/img/charities/logos/guidedogsaus.png'),
        categories: [ANIMALS, HEALTH],
        description:
          'A gift in your Will to Guide Dogs is a life-changing legacy, providing independence and freedom for Australians with low vision or blindness for many years to come.',
      },
    },
  },
  megavoice: {
    name: 'MegaVoice Australia Ltd',
    address: '136/3 Manta Place, Chiswick, NSW, 2046',
    abn: '94 631 590 899',
    logo: require('~/assets/img/charities/logos/megavoice.png'),
    categories: [EDUCATION],
  },
  lifeflight: {
    name: 'LifeFlight Foundation Limited',
    address: '160 Robina Town Centre Drive, Robina, QLD, 4226',
    abn: '63 618 170 184',
    logo: require('~/assets/img/charities/logos/lifeflight.png'),
    categories: [HEALTH],
  },
};

export { featuredCharities, referralCharities };
