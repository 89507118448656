function showChat() {
  if (typeof window !== 'undefined') {
    if (window.zE) {
      window.zE('webWidget', 'open');
    } else {
      console.error('No chat implementation available.');
      window.location.href =
        'mailto:support@safewill.com?subject=Support%20Query';
    }
  }
}

export { showChat };
